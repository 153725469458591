import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import vuetify from '@/plugins/Vuetify'
import i18n from '@/plugins/VueI18n'

import '@/plugins/Filters'
import '@/plugins/Prototype'
import '@/plugins/Vmask'
import '@/plugins/Dayjs'
import '@/plugins/Buefy'
import '@/plugins/GlobalComponents'
import '@/plugins/VeeValidate'
import '@/plugins/VueTreeSelect'
import '@/plugins/Chartist'
import '@/plugins/Base'
import '@/plugins/VueCountryFlag'
import '@/plugins/VueClipbroad'
import '@/plugins/VuetifyTelInput'

import '@/assets/css/fonts.css'
import '@/assets/css/main.css'

import JsonViewer from 'vue-json-viewer'
import 'vue-json-viewer/style.css'

Vue.use(JsonViewer)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App)
}).$mount('#app')
