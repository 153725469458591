<template>
  <v-fade-transition mode="out-in">
    <router-view v-if="!errorPageCode" />
    <error-page
      v-else
      :error-code="errorPageCode" />
  </v-fade-transition>
</template>

<script>
import { mapGetters } from 'vuex'
import errorPage from '@/components/layout/Error'

export default {
  components: {
    errorPage
  },
  computed: {
    ...mapGetters({
      errorPageCode: 'Components/errorPage'
    })
  }
}
</script>

<style lang="scss" scoped>
</style>
