export default {
  path: '/report',
  component: () => import('@/views/reports/index.vue'),
  children: [
    {
      path: '',
      name: 'ReportColors',
      component: () => import('@/views/reports/pages/ReportColors.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'area_manager',
          'marketing',
          'developer',
          'accounting_manager',
          'designer',
          'merchandising_planner',
          'merchandiser',
          'creative',
          'accounting'
        ]
      }
    },
    {
      path: 'new-member',
      name: 'NewMemberReport',
      component: () => import('@/views/reports/pages/NewMemberReport.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'area_manager',
          'accounting_manager',
          'marketing',
          'developer'
        ]
      }
    },
    {
      path: 'cofen-chat-report',
      name: 'CofenChatReport',
      component: () => import('@/views/reports/pages/ChatCofenReport.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'accounting_manager',
          'area_manager',
          'marketing',
          'developer'
        ]
      }
    },
    {
      path: 'cofen-feedback-report',
      name: 'CofenFeedbackReport',
      component: () => import('@/views/reports/pages/FeedbackCofenReport.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'online_admin',
          'developer'
        ]
      }
    },
    {
      path: '/size',
      name: 'ReportColorsWithSizes',
      component: () => import('@/views/reports/pages/ReportColorsWithSizes.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'area_manager',
          'marketing',
          'developer',
          'designer',
          'accounting_manager',
          'merchandising_planner',
          'merchandiser',
          'creative',
          'accounting'
        ]
      }
    },
    {
      path: '/nngp',
      name: 'ReportNNGP',
      component: () => import('@/views/reports/pages/ReportNNGP.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'area_manager',
          'marketing',
          'developer',
          'accounting_manager',
          'merchandising_planner',
          'merchandiser',
          'accounting'
        ]
      }
    },
    {
      path: '/sales',
      name: 'SaleReport',
      component: () => import('@/views/reports/pages/SaleReports.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'area_manager',
          'marketing',
          'developer',
          'accounting_manager',
          'merchandising_planner',
          'merchandiser',
          'accounting'
        ]
      }
    },
    {
      path: '/sales-sg',
      name: 'SaleReportSG',
      component: () => import('@/views/reports/pages/SaleReportsSG.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'area_manager',
          'marketing',
          'developer',
          'accounting_manager',
          'merchandising_planner',
          'merchandiser',
          'accounting'
        ]
      }
    },
    {
      path: '/pick-pack',
      name: 'PickPackReport',
      component: () => import('@/views/reports/pages/PickPackReports.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'area_manager',
          'marketing',
          'developer',
          'merchandising_planner',
          'accounting',
          'accounting_manager',
          'support',
          'warehouse_manager',
          'store_manager',
          'acting_assist_store_manager',
          'assist_store_manager'
        ]
      }
    },
    {
      path: '/dhl-pick-up',
      name: 'DhlPickupReport',
      component: () => import('@/views/reports/pages/DhlPickupReport.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'area_manager',
          'marketing',
          'developer',
          'merchandising_planner',
          'accounting_manager',
          'merchandiser',
          'accounting',
          'store_manager',
          'acting_assist_store_manager',
          'assist_store_manager'
        ]
      }
    },
    {
      path: '/stock-cost-value',
      name: 'StockCostValue',
      component: () => import('@/views/reports/pages/StockCostValue.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'area_manager',
          'marketing',
          'developer',
          'accounting_manager',
          'accounting'
        ]
      }
    },
    {
      path: '/tracking-behavior-overall',
      name: 'TrackingBehaviorOverallReport',
      component: () => import('@/views/reports/pages/TrackingBehaviorOverall.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'accounting_manager',
          'area_manager',
          'developer'
        ]
      }
    },
    {
      path: '/tracking-behavior-warehouse',
      name: 'TrackingBehaviorWarehouseReport',
      component: () => import('@/views/reports/pages/TrackingBehaviorWarehouse.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'accounting_manager',
          'area_manager',
          'developer'
        ]
      }
    },
    {
      path: '/offline-conversion-report',
      name: 'OfflineConversionReport',
      component: () => import('@/views/reports/pages/OfflineConversion.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'accounting_manager',
          'area_manager',
          'marketing',
          'developer'
        ]
      }
    },
    {
      path: '/branch-line-linked-report',
      name: 'BranchLineLinkedReport',
      component: () => import('@/views/reports/pages/BranchLineLinkedReport.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'area_manager',
          'accounting_manager',
          'marketing',
          'developer',
          'store_manager',
          'acting_assist_store_manager',
          'assist_store_manager'
        ]
      }
    },
    {
      path: '/cashier-line-linked-report',
      name: 'CashierLineLinkedReport',
      component: () => import('@/views/reports/pages/CashierLineLinkedReport.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'area_manager',
          'marketing',
          'accounting_manager',
          'developer',
          'store_manager',
          'acting_assist_store_manager',
          'assist_store_manager'
        ]
      }
    },
    {
      path: '/in-dispute-report',
      name: 'IdpReport',
      component: () => import('@/views/reports/pages/IdpReport.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'area_manager',
          'marketing',
          'developer',
          'creative',
          'merchandising_planner',
          'merchandiser',
          'online_admin',
          'accounting_manager',
          'accounting',
          'support',
          'warehouse_manager',
          'warehouse',
          'store_manager',
          'acting_assist_store_manager',
          'assist_store_manager',
          'sales_staff'
        ]
      }
    }
  ]
}
