<template>
  <v-btn
    v-bind="$attrs"
    icon
    v-on="$listeners"
    @mouseover="setHover(true)"
    @mouseleave="setHover(false)">
    <v-icon>
      {{ icon }}
    </v-icon>
  </v-btn>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: ''
    },
    iconHover: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      isHover: false
    }
  },
  computed: {
    icon () {
      return this.isHover ? this.iconHover : this.iconName
    }
  },
  methods: {
    setHover (data) {
      this.isHover = data
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
