import dayjs from '@/plugins/Dayjs'

export default (order) => {
  if (order.currentState === 'pending') {
    const isExpired = dayjs().isSameOrAfter(
      dayjs(order.expire).add(7, 'h')
    )
    if (isExpired) {
      return 'expired'
    }
    return order.currentState
  }
  return order.currentState
}
