<template>
  <div class="gw-short-logo">
    <img
      :src="require('@/../public/svg/gw-short-logo.svg')"
      width="30"
      height="48"
      alt="gw-short-logo">
  </div>
</template>

<script>
export default {
}
</script>

<style lang="scss">
.gw-short-logo {
  // padding: 10px 15px;
  margin-right: 10px;
  img {
    display: block;
    user-select: none;
  }
}
</style>
