export default {
  path: '/csv-to-barcode',
  component: () => import('@/views/csvToBarcode/index.vue'),
  children: [
    {
      path: '',
      name: 'Barcode',
      component: () => import('@/views/csvToBarcode/pages/barcode.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'area_manager',
          'developer',
          'accounting_manager',
          'support',
          'inventory_and_costing',
          'warehouse_manager',
          'warehouse',
          'store_manager',
          'acting_assist_store_manager',
          'assist_store_manager',
          'sales_staff'
        ]
      }
    },
    {
      path: 'godex-barcode',
      name: 'BarcodeGodex',
      component: () => import('@/views/csvToBarcode/pages/barCodeGodexPage.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'area_manager',
          'developer',
          'accounting_manager',
          'support',
          'inventory_and_costing',
          'warehouse_manager',
          'warehouse',
          'store_manager',
          'acting_assist_store_manager',
          'assist_store_manager',
          'sales_staff'
        ],
        layout: 'Blank'
      }
    }
  ]
}
