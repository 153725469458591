export default {
  path: '/order',
  component: () => import('@/views/order/index.vue'),
  children: [
    {
      path: '',
      name: 'OrderList',
      redirect: { name: 'OrderListByStatus', params: { orderStatus: 'all' } }
    },
    {
      path: 'print-order-summary',
      name: 'PrintOrderSummary',
      component: () => import('@/views/order/page/PrintOrderSummary.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'area_manager',
          'accounting_manager',
          'accounting',
          'developer',
          'support',
          'warehouse_manager',
          'warehouse',
          'store_manager',
          'acting_assist_store_manager',
          'assist_store_manager',
          'sales_staff'
        ],
        layout: 'Blank'
      }
    },
    {
      path: 'print-order-summary-list',
      name: 'PrintOrderSummaryList',
      component: () => import('@/views/order/page/PrintOrderSummaryList.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'area_manager',
          'accounting_manager',
          'developer',
          'support',
          'warehouse_manager',
          'warehouse',
          'store_manager',
          'acting_assist_store_manager',
          'assist_store_manager',
          'sales_staff'
        ]
      }
    },
    {
      path: 'print-label',
      name: 'OrderPrintLabel',
      component: () => import('@/views/order/page/PrintLabel.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'area_manager',
          'accounting_manager',
          'developer',
          'support',
          'warehouse_manager',
          'warehouse',
          'store_manager',
          'acting_assist_store_manager',
          'assist_store_manager',
          'sales_staff'
        ],
        layout: 'Blank'
      }
    },
    {
      path: 'new-order',
      name: 'NewOrder',
      component: () => import('@/views/order/page/NewOrder.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'marketing',
          'developer',
          'online_admin',
          'support',
          'warehouse_manager',
          'accounting_manager',
          'warehouse',
          'store_manager',
          'acting_assist_store_manager',
          'assist_store_manager',
          'sales_staff'
        ]
      }
    },
    {
      path: ':orderStatus',
      name: 'OrderListByStatus',
      component: () => import('@/views/order/page/OrderList.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'area_manager',
          'marketing',
          'developer',
          'online_admin',
          'accounting_manager',
          'accounting',
          'support',
          'warehouse_manager',
          'warehouse',
          'store_manager',
          'acting_assist_store_manager',
          'assist_store_manager',
          'sales_staff'
        ]
      }
    }
  ]
}
