import { getAuthToken } from '@/assets/js/Authentication'
import HttpRequest from './HttpRequest'

class MemberProvider extends HttpRequest {
  getMembersByPaginate (query) {
    this.setHeader(getAuthToken())
    return this.getByPagination('/members', query)
  }

  getNewMemberReport (payload) {
    this.setHeader(getAuthToken())
    return this.get(`/report/new-member?startDate=${payload.startDate}&endDate=${payload.endDate}&channel=${payload.channel}`)
  }

  getMember (param) {
    this.setHeader(getAuthToken())
    return this.get(`/members${param}`)
  }

  getMemberById (id) {
    this.setHeader(getAuthToken())
    return this.get(`/members/${id}`)
  }

  getDashBoard () {
    this.setHeader(getAuthToken())
    return this.get('/members/dash-board')
  }

  getMemmberOrderHistoryList (query) {
    this.setHeader(getAuthToken())

    return this.get('/members/order-history/list', query)
  }

  getMemmberOrderHistoryListSG (query) {
    this.setHeader(getAuthToken())

    return this.get('/members/order-history/list/sg', query)
  }

  getMemberSpendingById (id, query) {
    this.setHeader(getAuthToken())
    return this.get(`/members/${id}/spending-log`, query)
  }

  getMemberByTel (tel) {
    this.setHeader(getAuthToken())
    return this.get(`/members/${tel}/tel`)
  }

  sendPromotionCode (tel) {
    this.setHeader(getAuthToken())
    return this.post(`/members/${tel}/send-promotion`)
  }

  deleteMember (id) {
    this.setHeader(getAuthToken())
    return this.delete(`/members?id=${id}`)
  }

  updateMember (id, member) {
    this.setHeader(getAuthToken())
    return this.put(`/members/${id}`, member)
  }

  createMember (member) {
    this.setHeader(getAuthToken())
    return this.post('/members', member)
  }

  addMemberSpending (spend) {
    this.setHeader(getAuthToken())
    return this.put('/members/add-spending', spend)
  }

  memberOrderlist (options) {
    this.setHeader(getAuthToken())
    return this.getByPagination('/members/order-history/paginate', options)
  }

  getOrdersByMemberId (memberId, options) {
    this.setHeader(getAuthToken())
    return this.getByPagination(`/members/${memberId}/order-history`, options)
  }

  getCashierReport (options) {
    this.setHeader(getAuthToken())
    return this.getByPagination('members/order-history/cashier', options)
  }

  getMembersByTels (payload) {
    this.setHeader(getAuthToken())
    return this.post('/members/get-ids', payload)
  }

  getMembersByIds (payload) {
    this.setHeader(getAuthToken())
    return this.post('/members/get-tels', payload)
  }

  editAddressesById (id, payload) {
    this.setHeader(getAuthToken())
    return this.patch(`/members/${id}/addresses`, payload)
  }
}

export default MemberProvider
