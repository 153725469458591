export default ({ next, auth, store, skip }) => {
  if (!auth.getAuth()) {
    return skip({
      name: 'Login'
    })
  }
  const data = auth.getAuthDecode()
  if (data && ((data.exp - 1800) * 1000) <= new Date().getTime()) {
    return store.dispatch('Components/tokenExpire', () => skip({
      name: 'Login'
    }))
  }
  return next()
}
