<template>
  <v-row align="center">
    <v-col
      v-if="label"
      cols="auto">
      <v-skeleton-loader
        :loading="loading"
        :width="65"
        :height="28"
        type="text"
        class="input-skeleton-loader">
        <label :class="{ 'text-bold': labelBold }">
          {{ label }}
        </label>
      </v-skeleton-loader>
    </v-col>
    <v-spacer />
    <v-col
      v-if="showButton"
      cols="auto">
      <v-dialog
        v-model="dialog"
        persistent
        max-width="600"
        @click:outside="setDialog(false)">
        <template v-slot:activator="{ on, attrs }">
          <v-skeleton-loader
            :loading="loading"
            :height="28"
            type="button"
            class="input-skeleton-loader">
            <v-btn
              color="primary"
              dark
              small
              v-bind="attrs"
              v-on="on">
              Upload
            </v-btn>
          </v-skeleton-loader>
        </template>
        <v-card class="pa-5">
          <v-card-title class="headline">
            <label>Upload</label>
            <v-spacer />
            <v-btn
              icon
              @click="setDialog(false)">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-actions>
            <div class="upload-file">
              <b-upload
                v-model="upload"
                v-bind="$attrs.upload"
                drag-drop
                :multiple="multiple"
                @input="handleUploadFile($event)">
                <div class="d-flex flex-column justify-center align-center">
                  <v-icon size="60">
                    mdi-image-filter-hdr
                  </v-icon>
                  <span class="caption">{{ caption }}</span>
                </div>
              </b-upload>
            </div>
          </v-card-actions>
          <v-overlay
            :value="uploading"
            :z-index="999"
            absolute>
            <v-progress-circular
              :size="200"
              :width="10"
              color="light-blue"
              indeterminate>
            </v-progress-circular>
          </v-overlay>
        </v-card>
      </v-dialog>
    </v-col>
    <v-col :cols="12">
      <v-skeleton-loader
        :loading="loading"
        :height="230"
        type="image"
        class="input-skeleton-loader">
        <div
          v-if="draggableEnable"
          class="img-preview images-row">
          <draggable
            v-model="draggableImg"
            tag="div"
            v-bind="dragOptions"
            @start="setDragging(true)"
            @end="setDragging(false)">
            <transition-group
              type="transition"
              name="flip-list"
              tag="div"
              class="row ma-0 draggable-items">
              <div
                v-for="(img, index) in draggableImg"
                :key="`draggableImg-${index}`"
                class="col col-auto draggable-item">
                <div class="draggable-img">
                  <v-img
                    v-if="img"
                    :aspect-ratio="imgAspectRatio"
                    :src="img"
                    :lazy-src="`${replaceURL(img)}?w=2&h=2`"
                    :width="150"
                    class="elevation-1"
                    alt="img">
                  </v-img>
                  <gw-icon-hover
                    v-if="!disabled"
                    class="remove-img"
                    icon-name="mdi-delete"
                    icon-hover="mdi-delete-empty"
                    @click="removeImg(index)" />
                </div>
              </div>
              <div
                v-if="!limit || (limit && draggableImg.length < limit)"
                :key="`draggableImg-upload`"
                class="upload-item-box">
                <div
                  class="upload-file">
                  <b-upload
                    v-model="upload"
                    v-bind="$attrs.upload"
                    drag-drop
                    :multiple="multiple"
                    @input="handleUploadFile($event)">
                    <div class="d-flex flex-column justify-center align-center">
                      <v-icon size="60">
                        mdi-image-filter-hdr
                      </v-icon>
                      <span class="caption">{{ caption }}</span>
                    </div>
                  </b-upload>
                </div>
              </div>
            </transition-group>
          </draggable>
        </div>
        <div
          v-else
          class="img-preview img-preview-disabled">
          <div class="upload-file">
            <b-upload
              v-model="upload"
              v-bind="$attrs.upload"
              drag-drop
              :multiple="multiple"
              @input="handleUploadFile">
              <div class="d-flex flex-column justify-center align-center">
                <v-icon size="60">
                  mdi-image-filter-hdr
                </v-icon>
                <span class="caption text-uppercase">{{ caption }}</span>
              </div>
            </b-upload>
          </div>
        </div>
      </v-skeleton-loader>
    </v-col>
  </v-row>
</template>

<script>
import draggable from 'vuedraggable'
import { uploadToBucket } from '@/assets/js/AwsS3'

export default {
  components: {
    draggable
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    multiple: {
      type: Boolean,
      default: false
    },
    value: {
      type: [Array, String],
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    },
    imgAspectRatio: {
      type: Number,
      default: 1
    },
    s3Path: {
      type: String,
      required: true
    },
    validate: {
      type: Boolean,
      required: false
    },
    validateFunc: {
      type: Function,
      required: false,
      default: () => null
    },
    caption: {
      type: String,
      default: 'click or drop file for upload'
    },
    showButton: {
      type: Boolean,
      default: false
    },
    labelBold: {
      type: Boolean,
      default: false
    },
    needCompress: {
      type: Boolean,
      default: false
    },
    limit: {
      type: Number,
      default: 0
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      draggableImg: [],
      dialog: false,
      upload: null,
      uploading: false,
      isDragging: false,
      dragOptions: {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      }
    }
  },
  computed: {
    transitionName () {
      return !this.isDragging ? 'flip-list' : null
    },
    draggableEnable () {
      return this.draggableImg.length > 0 && !!this.draggableImg[0]
    }
  },
  watch: {
    draggableImg (newVal) {
      if (this.multiple) {
        this.$emit('input', newVal)
      } else {
        this.$emit('input', newVal[0] ? newVal[0] : '')
      }
    },
    value (newVal) {
      if (this.multiple) {
        this.draggableImg = newVal
      } else {
        this.draggableImg = newVal ? [newVal] : []
      }
    }
  },
  created () {
    if (this.value) {
      if (Array.isArray(this.value)) {
        this.setDraggableImg(this.value)
      } else {
        this.setDraggableImg([this.value])
      }
    }
  },
  methods: {
    setUpload (value) {
      this.upload = value
    },
    setDraggableImg (value, push) {
      if (this.multiple && push) {
        this.draggableImg.push(...value)
      } else {
        this.draggableImg = new Array(...value)
      }
    },
    setDialog (value) {
      this.dialog = value
    },
    setDragging (value) {
      this.isDragging = value
    },
    replaceURL (value) {
      return value.replace(process.env.VUE_APP_S3_URL, process.env.VUE_APP_S3_IMGIX)
    },
    removeImg (index) {
      if (this.draggableImg.length === 1) {
        this.draggableImg = []
      } else {
        this.draggableImg.splice(index, 1)
      }
    },
    async handleUploadFile (event) {
      let valid = true

      if (this.validate) {
        valid = await this.validateFunc(event)
      }

      if (valid) {
        let imgSrc = null
        if (this.multiple) {
          let diff = event.length

          if (this.limit && (event.length + this.draggableImg.length) > this.limit) {
            diff = this.limit - this.draggableImg.length
          }

          for (let i = 0; i < diff; i++) {
            if (!imgSrc || imgSrc.length === 0) {
              imgSrc = []
            }

            imgSrc.push(uploadToBucket(event[i], this.s3Path, this.needCompress))
          }

          imgSrc = await Promise.all(imgSrc)
        } else {
          imgSrc = [await uploadToBucket(event, this.s3Path, this.needCompress)]
        }
        this.setDraggableImg(imgSrc.map((r) => this.replaceURL(r.mediaUrl)), true)
        this.setDialog(false)
        this.setUpload(null)
      } else {
        console.warn('handleUploadFile valid false')
      }
    }
  }
}
</script>

<style lang='scss'>
.upload-file {
  border-radius: 6px;
  position: relative;
  width: 100%;
  display: flex;
  min-height: 225px;

  .upload input[type=file] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    outline: none;
    cursor: pointer;
    z-index: -1;
  }
  .upload.control {
    display: grid;
    width: 100%;

    .upload-draggable {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding: 0.25em;
      border: 1px dashed #b5b5b5;
      border-radius: 6px;
      cursor: pointer;
    }
  }
}
.img-preview {
  border-radius: 6px;
  width: 100%;
  min-height: 225px;
  border: 1px dashed #b5b5b5;
  padding: 5px 10px;
}
.img-preview-disabled {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  cursor: pointer;

  .upload-file .upload.control .upload-draggable {
    border: 0;
  }
}
.draggable-items {
  flex-wrap: wrap;
  overflow-x: auto;
}
.draggable-item {
  position: relative;
  button.remove-img.v-btn {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
  }
  button.remove-img.v-btn:hover {
    color: #F44336 !important;
    caret-color: #F44336 !important;
  }
}
.draggable-img {
  position: relative;
  cursor: grab;
}
.draggable-img:active {
  cursor: grabbing;
}
.draggable-img:before {
  content: '';
  width: 100%;
  height: 100%;
  background-color: transparent;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  transition: background-color .25s;
}
.draggable-img:hover:before {
  background-color: #b5b5b580;
}
.upload-file.none-file {
  padding: 0;
  border: 0;
}
.input-skeleton-loader .v-skeleton-loader__image {
  width: 100%;
  height: 100%;
}
.img-preview .draggable-items .upload-file {
  min-height: unset;
  height: 100%;
}
.upload-item-box {
  padding: 12px;
  width: 200px;
}
.text-bold {
  font-weight: bold;
}
</style>
