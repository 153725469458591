import CheckOrderStatusState from '@/assets/js/CheckOrderStatusState'
import OrderProvider from '@/resources/OrderProvider'
import { defaultOrderData, defaultOrderSku, defaultOrderPromotion } from '@/assets/js/DefaultOrderData'
import OrderChannel from '@/assets/json/OrderChannel.json'
import MemberProvider from '@/resources/MemberProvider'
import PromotionProvider from '@/resources/PromotionProvider'

const orderChannel = OrderChannel.reduce((obj, item) => Object.assign(obj, { [item.value]: item.label }), {})
const OrderService = new OrderProvider()
const MemberService = new MemberProvider()
const PromotionService = new PromotionProvider()

const calcDiscountForEachSkus = (discount, sku) => {
  const totalPrice = sku.amount * sku.unitPrice || sku.fullPrice
  let { discountPrice } = discount
  const { discountType } = discount
  if (discountType === 'percent') {
    if (discountPrice > 100) {
      discountPrice = 100
    } else if (discountPrice <= 0) {
      discountPrice = 0
    }
    discountPrice = (discountPrice * totalPrice) / 100
  }

  discountPrice = discountPrice > 0 ? discountPrice : 0

  return totalPrice - discountPrice
}

const removeDuplicateOrder = (orders, store) => {
  const results = []
  for (const order of orders) {
    if (!store.some((each) => each.id === order.id)) {
      results.push(order)
    }
  }

  return results
}

// const getPromotionNumberByPercentage = (fullPrice, discount = 0) => (fullPrice * discount) / 100

const priceCalculator = (num1 = 0, num2 = 0, operation) => {
  try {
    const number1 = Number(num1)
    const number2 = Number(num2)
    if ((number1 || number1 === 0) && (number2 || number2 === 0)) {
      // eslint-disable-next-line no-eval
      const stringToCalc = eval
      const results = stringToCalc(`${number1}${operation}${number2}`)
      return results > 0 ? results : 0
    }
    const error = {
      code: 400,
      message: 'Number went wrong'
    }
    throw error
  } catch (error) {
    console.error('priceCalculator', error)
    throw error
  }
}
const mapOrderDeatil = (order) => ({
  ...defaultOrderData,
  ...order,
  promotionCode: order.promotionCode || {},
  currentState: CheckOrderStatusState(order),
  gotMember: true,
  skus: order.skus.map((sku) => ({
    ...defaultOrderSku,
    ...sku,
    unitPrice: priceCalculator(sku.fullPrice, sku.amount, '/'),
    discountPrice: priceCalculator(sku.fullPrice, sku.price, '-')
  }))
})
const mapDataVerifyCode = (code, member, skus, channel) => {
  const newData = {
    code,
    member,
    channel,
    items: skus.map((sku) => ({
      skuId: sku.id,
      code: sku.code,
      name: sku.name,
      amount: sku.amount,
      price: sku.price,
      fullPrice: sku.fullPrice,
      productId: sku.productId,
      categories: sku.categories,
      tags: sku.tags
    }))
  }
  return newData
}

const state = {
  printList: [],
  allSubOrders: {},
  isFetchingOrderList: false,
  isFetchingOrderCount: false,
  isFetchingOrder: false,
  orderList: [],
  orderIdSelected: '',
  orderDetail: defaultOrderData,
  orderMember: defaultOrderData.member,
  backupOrderDetail: defaultOrderData,
  statusList: {
    all: {
      label: 'All',
      count: 0
    },
    pending: {
      label: 'Pending',
      count: 0
    },
    reserved: {
      label: 'Reserved',
      count: 0
    },
    paid: {
      label: 'Paid',
      count: 0
    },
    waiting: {
      label: 'Waiting',
      count: 0
    },
    ready_to_ship: {
      label: 'RTS',
      count: 0
    },
    completed: {
      label: 'Completed',
      count: 0
    },
    exchange_return: {
      label: 'E/R',
      count: 0
    },
    void: {
      label: 'Void',
      count: 0
    },
    expired: {
      label: 'Expired',
      count: 0
    },
    deleted: {
      label: 'Deleted',
      count: 0
    }
  },
  isGotMember: false,
  isVerifiedCode: false,
  productModalActive: false,
  isMainOrder: true
}

const actions = {
  setOrderDetailBrand ({ commit }, payload) {
    commit('SET_ORDER_DETAIL_BRAND', payload)
  },
  setOrderDetailChannel ({ commit }, payload) {
    commit('SET_ORDER_DETAIL_CHANNEL', payload)
    commit('SET_ORDER_DETAIL_SUB_ORDERS', [])
    commit('SET_SKUS', [])
    commit('SET_ALL_SUB_ORDERS', {})
  },
  setPrintList ({ commit }, payload) {
    commit('SET_PRINT_LIST', payload)
  },
  setAllSubOrders ({ commit }, payload) {
    commit('SET_ALL_SUB_ORDERS', payload)
  },
  setIsMainOrder ({ commit }, payload = false) {
    commit('SET_IS_MAIN_ORDER', !!payload)
    return !!payload
  },
  setOrderDetail ({ commit }, payload) {
    commit('SET_ORDER_DETAIL', payload)
  },
  setOrderDetailDHLPackages ({ commit }, payload) {
    commit('SET_ORDER_DETAIL_DHL_PACKAGES', payload)
  },
  setBackupOrderDetail ({ commit }, payload) {
    commit('SET_BACKUP_ORDER_DETAIL', payload)
  },
  async fetchOrderCount ({ dispatch, commit }, payload) {
    try {
      let orderCount = null
      commit('SET_IS_FETCHING_ORDER_COUNT', true)
      const paramsQuery = {
        channel: payload.channel,
        brand: payload.brand || 'all',
        search: payload.search,
        advanceSearch: payload.advanceSearch || '0'
      }
      if (payload.warehouseId === 0) {
        const { data } = await OrderService.getOrderCount(paramsQuery)
        orderCount = data
      } else {
        const { data } = await OrderService.getOrderCountByWarehouse(payload.warehouseId, paramsQuery)
        data.deleted = 0
        orderCount = data
      }

      if (orderCount) {
        for (const status of Object.keys(orderCount)) {
          if (status === 'readyToShip') {
            dispatch('setStatusListByStatus', {
              status: 'ready_to_ship',
              count: orderCount[status] || 0
            })
          } else if (status === 'exchangeReturn') {
            dispatch('setStatusListByStatus', {
              status: 'exchange_return',
              count: orderCount[status] || 0
            })
          } else {
            dispatch('setStatusListByStatus', {
              status,
              count: orderCount[status] || 0
            })
          }
        }
      }
    } catch (error) {
      console.error('fetchOrderCount', error)
    } finally {
      commit('SET_IS_FETCHING_ORDER_COUNT', false)
    }
  },
  async fetchOrderList ({ commit }, payload) {
    try {
      let orderList = null
      const query = {
        currentState: payload.currentState || null,
        channel: payload.channel || 'all',
        advanceSearch: payload.advanceSearch || '0',
        page: payload.page || 1,
        perPage: payload.perPage || 25,
        warehouse: !payload.isMainOrder ? payload.warehouse : null,
        brand: payload.brand || 'all',
        search: payload.search || null,
        sortBy: payload.sortBy || null,
        status: payload.status,
        sortOrder: payload.sortDesc || 'desc'
      }
      if (payload.isMainOrder) {
        const { data } = await OrderService.getOrderList(query)
        orderList = data
      } else if (payload.status === 'deleted') {
        // * Note if order warehouse && status == deleted then orderList = []
        orderList = {
          limit: 25,
          page: 1,
          pages: 1,
          results: [],
          total: 1
        }
      } else {
        const { data } = await OrderService.getOrderListByWarehouse(query)
        orderList = data
      }
      commit('PUSH_ORDER_LIST', orderList.results.map((order) => ({
        ...order,
        channel: orderChannel[order.channel] || order.channel,
        currentState: CheckOrderStatusState(order)
      })))
      return orderList
    } catch (error) {
      console.error('fetchOrderList', error)
      throw error
    }
  },
  pushOrderList ({ commit }, payload) {
    commit('PUSH_ORDER_LIST', payload)
  },
  spliceOrderList ({ commit }, payload) {
    commit('SPLICE_ORDER_LIST', payload)
  },
  clearOrderList ({ commit }) {
    commit('CLAER_ORDER_LIST')
  },
  async selectOrderId ({ commit, dispatch, rootGetters }, payload) {
    const { id, isMainOrder } = payload
    commit('SET_SELECT_ORDER_ID', id)
    let fetchData = null
    if (id === 'create') {
      dispatch('setIsMainOrder', true)
      dispatch('setSelectOrder', {
        ...defaultOrderData,
        createdAt: new Date(),
        expire: new Date(new Date().setDate(new Date().getDate() + 1)),
        channel: 'manual',
        shipping: rootGetters['Store/mapShipping'][0]
      })
    } else {
      try {
        if (isMainOrder) {
          const { data } = await OrderService.getOrderById(id)
          fetchData = data
        } else {
          const { data } = await OrderService.getOrderWarehouseById(id)
          fetchData = data
        }
        if (fetchData?.id) {
          const skus = fetchData.skus.map((sku) => ({ ...sku, discountPrice: sku.fullPrice - sku.price }))
          const subOrders = fetchData.subOrders?.map((sku) => ({ ...sku, discountPrice: sku.fullPrice - sku.price })) || []
          dispatch('setSelectOrder', mapOrderDeatil({ ...fetchData, subOrders, skus }))
        } else {
          const error = {
            code: 400,
            message: 'Sosomething went wrong'
          }
          throw error
        }
      } catch (error) {
        console.error('selectOrderId', error)
      }
    }
  },
  setSelectOrder ({ commit }, payload) {
    commit('SET_SELECT_ORDER', payload)
  },
  clearSelectedOrderId ({ commit }) {
    commit('CLEAR_SELECTED_ORDER_ID')
    commit('CLEAR_SELECTED_ORDER')
  },
  setStatusListByStatus ({ commit, state }, payload) {
    const status = state.statusList[payload.status] || null
    if (status) {
      commit('SET_STATUSLIST_BY_STATUS', {
        status: payload.status,
        count: payload.count || 0
      })
    }
  },
  clearStatusListCount ({ commit, state }) {
    for (const status of Object.keys(state.statusList)) {
      commit('SET_STATUSLIST_BY_STATUS', {
        status,
        count: 0
      })
    }
  },
  setFetchingOrderList ({ commit }, payload) {
    commit('SET_FETCHING_ORDER_LIST', payload)
  },
  setFetchingOrder ({ commit }, payload) {
    commit('SET_FETCHING_ORDER', payload)
  },
  setExpireDate ({ commit }, payload) {
    commit('SET_EXPIRE_DATE', payload)
  },
  calcSkuPrice ({ commit, getters }, payload) {
    const { sku } = payload
    const discountType = payload.discountType || sku.discountType
    const orders = getters.allSubOrders
    const summary = {
      amount: 0,
      unitPrice: 0,
      discountPrice: 0
    }

    Object.keys(orders).forEach((warehouseId) => {
      if (warehouseId !== '0') {
        const found = orders[warehouseId].find((each) => sku.id === each.id)
        if (found) {
          summary.amount += found.amount
          summary.unitPrice = found.unitPrice
          summary.discountPrice = sku.discountPrice >= 0 ? sku.discountPrice : 0
        }
      }
    })

    Object.keys(orders).forEach((warehouseId) => {
      const indexInWarehouse = orders[warehouseId].findIndex((each) => sku.id === each.id)
      const item = orders[warehouseId][indexInWarehouse]
      if (indexInWarehouse !== -1) {
        const discount = {
          discountPrice: discountType === 'amount'
          ? (summary.discountPrice / summary.amount) * item.amount
          : summary.discountPrice,
          discountType
        }

        let price = calcDiscountForEachSkus(discount, { ...item, amount: item.amount })
        price = price >= 0 ? price : 0

        orders[warehouseId][indexInWarehouse] = { ...item, ...discount, price }
      }
    })
    const subOrders = []
    Object.keys(orders).forEach((warehouseId) => {
      if (warehouseId !== '0') {
        const subOrder = orders[warehouseId]
        subOrders.push({ skus: subOrder, warehouse: subOrder[0].warehouse })
      }
    })
    const orderDetail = { ...getters.orderDetail }

    commit('SET_ALL_SUB_ORDERS', orders)
    commit(
      'SET_ORDER_DETAIL',
      {
        ...orderDetail,
        skus: orders['0'],
        subOrders
      }
    )
  },
  setShippingFee ({ commit }, payload) {
    commit('SET_SHIPPING_FEE', Number(payload) || 0)
  },
  setShipping ({ state, commit }, payload) {
    commit('SET_SHIPPING', {
      ...state.orderDetail.shipping,
      ...payload
    })
  },
  async getMember ({ dispatch, state, commit }) {
    const memberTel = state.orderDetail.member.tel
    const tel = memberTel && memberTel.charAt(0) === '0' ? `+66${memberTel.substr(1)}` : memberTel
    const { data } = await MemberService.getMembersByTels({
      tels: [tel]
    })
    const memberData = data[0]
    if (memberData.tel) {
      commit('SET_ORDER_MEMBER', {
        ...state.orderDetail.member,
        tel: memberData.tel,
        id: memberData.id,
        firstName: memberData.firstName,
        lastName: memberData.lastName,
        email: memberData.email
      })
      dispatch('updateGotMember', true)
    }
    // dispatch('cancelVerifyCode')
    return {
      ...memberData
    }
  },
  updateMemberTel ({ commit }, payload) {
    commit('SET_MEMBER_TEL', payload)
  },
  updateMemberId ({ commit }, payload) {
    commit('SET_MEMBER_ID', payload)
  },
  updateMemberFirstName ({ commit }, payload) {
    commit('SET_MEMBER_FIRST_NAME', payload)
  },
  updateMemberLastName ({ commit }, payload) {
    commit('SET_MEMBER_LAST_NAME', payload)
  },
  updateMemberEmail ({ commit }, payload) {
    commit('SET_MEMBER_EMAIL', payload)
  },
  updateGotMember ({ commit }, payload = false) {
    commit('SET_GOT_MEMBER', payload)
  },
  setProductModalActive ({ commit }, payload = false) {
    commit('SET_PRODUCT_MODAL_ACTIVE', payload)
  },
  setSkus ({ commit }, payload) {
    commit('SET_SKUS', payload)
  },
  setNote ({ commit }, payload) {
    commit('SET_NOTE', payload)
  },
  setPromotionCode ({ commit }, payload) {
    commit('SET_PROMOTION_CODE_CODE', payload)
  },
  async validatePromotion ({ commit, state }, payload) {
    const { member, skus, orderId, currentState, channel } = state.orderDetail
    try {
      if (!state.isGotMember || !payload) {
        const error = {
          code: 400,
          message: 'Plaese get member and enter code.',
          type: 'alert'
        }
        throw error
      }
      const verifyData = mapDataVerifyCode(
        payload.toUpperCase(),
        {
          id: member?.id || null,
          tel: member.tel,
          email: member?.email || ''
        },
        skus,
        channel
      )
      const { data } = (currentState === 'prepare_create')
      ? await PromotionService.validatePromotion(verifyData)
      : await PromotionService.superValidatePromotion({ ...verifyData, orderId })
      if (data?.success && data?.promotionCode?.id) {
        commit('SET_PROMOTION_CODE', {
          ...defaultOrderPromotion,
          ...data.promotionCode,
          discountPrice: data.discount
        })
        commit('SET_ORDER_DISCOUNT', data.discount)
        commit('SET_IS_VERIFIED_CODE', true)
      } else if (!data.success && data?.promotionCode?.id) {
        const error = {
          code: 400,
          message: `${data.error.message}`,
          type: 'alert'
        }
        throw error
      } else {
        const error = {
          code: 500,
          message: 'Promotion code not have ID. Please contact developer team.'
        }
        throw error
      }
    } catch (error) {
      console.error('verifyCode', error)
      throw error
    }
  },
  cancelVaildateCode ({ commit }) {
    commit('SET_PROMOTION_CODE', {
      ...defaultOrderPromotion
    })
    commit('SET_ORDER_DISCOUNT', 0)
    commit('SET_IS_VERIFIED_CODE', false)
  },
  resetOrderState ({ commit }) {
    commit('RESET_ORDER_STATE')
  }
}

const mutations = {
  SET_ORDER_DETAIL_BRAND (state, payload) {
    state.orderDetail.brand = payload
  },
  SET_ORDER_DETAIL_CHANNEL (state, payload) {
    state.orderDetail.channel = payload
  },
  SET_ORDER_DETAIL_SUB_ORDERS (state, payload) {
    state.orderDetail.subOrders = payload
  },
  SET_PRINT_LIST (state, payload) {
    state.printList = [...payload]
  },
  SET_ORDER_DISCOUNT (state, payload) {
    state.orderDetail.discount = payload
  },
  SET_ALL_SUB_ORDERS (state, payload) {
    state.allSubOrders = { ...payload }
  },
  SET_ORDER_DETAIL (state, payload) {
    state.orderDetail = payload
  },
  SET_ORDER_DETAIL_DHL_PACKAGES (state, payload) {
    state.orderDetail.dhlPackages = payload
  },
  SET_BACKUP_ORDER_DETAIL (state, payload) {
    state.backupOrderDetail = payload
  },
  SET_IS_FETCHING_ORDER_COUNT (state, payload) {
    state.isFetchingOrderCount = payload
  },
  SET_IS_MAIN_ORDER (state, payload) {
    state.isMainOrder = payload
  },
  SET_NOTE (state, payload) {
    state.orderDetail.note = payload
  },
  SET_PROMOTION_CODE_CODE (state, payload) {
    state.orderDetail.promotionCode.code = payload
  },
  SET_IS_VERIFIED_CODE (state, payload = false) {
    state.isVerifiedCode = !!payload
  },
  SET_PROMOTION_CODE (state, payload) {
    state.orderDetail.promotionCode = payload
  },
  SET_PRODUCT_MODAL_ACTIVE (state, payload) {
    state.productModalActive = payload
  },
  SET_ORDER_MEMBER (state, payload) {
    state.orderDetail.member = payload
  },
  SET_MEMBER_TEL (state, payload) {
    state.orderDetail.member.tel = payload
  },
  SET_MEMBER_ID (state, payload) {
    state.orderDetail.member.id = payload
  },
  SET_MEMBER_FIRST_NAME (state, payload) {
    state.orderDetail.member.firstName = payload
  },
  SET_MEMBER_LAST_NAME (state, payload) {
    state.orderDetail.member.lastName = payload
  },
  SET_MEMBER_EMAIL (state, payload) {
    state.orderDetail.member.email = payload
  },
  SET_GOT_MEMBER (state, payload) {
    state.isGotMember = payload
  },
  PUSH_ORDER_LIST (state, payload) {
    const { orderList } = state
    const orders = removeDuplicateOrder(payload, orderList)
    state.orderList.push(...orders)
  },
  SPLICE_ORDER_LIST (state, payload) {
    state.orderList.push(payload, 1)
  },
  CLAER_ORDER_LIST (state) {
    state.orderList = []
  },
  SET_SELECT_ORDER_ID (state, payload) {
    state.orderIdSelected = payload
  },
  SET_SELECT_ORDER (state, payload) {
    state.orderDetail = payload
  },
  CLEAR_SELECTED_ORDER_ID (state) {
    state.orderIdSelected = ''
  },
  CLEAR_SELECTED_ORDER (state) {
    state.orderDetail = defaultOrderData
  },
  SET_STATUSLIST_BY_STATUS (state, payload) {
    state.statusList[payload.status].count = payload.count
  },
  SET_FETCHING_ORDER_LIST (state, payload) {
    state.isFetchingOrderList = payload
  },
  SET_FETCHING_ORDER (state, payload) {
    state.isFetchingOrder = payload
  },
  SET_EXPIRE_DATE (state, payload) {
    state.orderDetail.expire = payload
  },
  SPLICE_SKU (state, payload) {
    state.orderDetail.skus.splice(payload.index, 1, payload.sku)
  },
  SET_SKUS (state, payload) {
    state.orderDetail.skus = payload
  },
  SET_SHIPPING (state, payload) {
    state.orderDetail.shipping = payload
  },
  SET_SHIPPING_FEE (state, payload) {
    state.orderDetail.shipping.fee = payload
  },
  RESET_ORDER_STATE (state) {
    state.printList = []
    state.allSubOrders = {}
    state.isFetchingOrderList = false
    state.isFetchingOrder = false
    state.orderList = []
    state.orderIdSelected = ''
    state.orderDetail = defaultOrderData
    state.orderMember = defaultOrderData.member
    state.backupOrderDetail = defaultOrderData
    state.statusList = {
      all: {
        label: 'All',
        count: 0
      },
      pending: {
        label: 'Pending',
        count: 0
      },
      reserved: {
        label: 'Reserved',
        count: 0
      },
      paid: {
        label: 'Paid',
        count: 0
      },
      waiting: {
        label: 'Waiting',
        count: 0
      },
      ready_to_ship: {
        label: 'RTS',
        count: 0
      },
      completed: {
        label: 'Completed',
        count: 0
      },
      exchange_return: {
        label: 'E/R',
        count: 0
      },
      void: {
        label: 'Void',
        count: 0
      },
      expired: {
        label: 'Expired',
        count: 0
      },
      deleted: {
        label: 'Deleted',
        count: 0
      }
    }
    state.isGotMember = false
    state.isVerifiedCode = false
    state.productModalActive = false
    state.isMainOrder = true
  }
}

const getters = {
  printList: (state) => state.printList,
  allSubOrders: (state) => state.allSubOrders,
  onEditMode: (state) => state.orderDetail.currentState === 'edit' || state.orderDetail.currentState === 'prepare_create',
  backupOrderDetail: (state) => state.backupOrderDetail,
  orderList: (state) => state.orderList,
  statusList: (state) => state.statusList,
  isFetchingOrderList: (state) => state.isFetchingOrderList,
  isFetchingOrderCount: (state) => state.isFetchingOrderCount,
  isFetchingOrder: (state) => state.isFetchingOrder,
  orderDetailActive: (state) => !!state.orderIdSelected && !state.productModalActive,
  orderStatusState: (state) => state.orderDetail?.currentState || null,
  isEditingField: (state, getter) => getter.orderStatusState === 'prepare_create' || getter.orderStatusState === 'edit',
  orderDetail: (state) => state.orderDetail,
  orderDetailMember: (state) => state.orderDetail.member,
  isMainOrder: (state) => state.isMainOrder,
  hasOrderSkus: (state, getter) => getter.orderSkus.length > 0,
  orderSkus: (state) => state.orderDetail.skus,
  shipping: (state) => {
    const { shipping } = state.orderDetail
    return {
      fee: shipping.fee,
      method: shipping.method,
      providerName: shipping.providerName
    }
  },
  // skusTotalPrice: (state) => state.orderDetail.skus.reduce((sum, sku) => sum + sku.price, 0),
  skusTotalPrice: (state) => {
    let totalPrice = 0
    if (state.isMainOrder) {
      Object.keys(state.allSubOrders).forEach((key) => {
        if (key !== '0') {
          totalPrice += state.allSubOrders[key].reduce((total, acc) => total + acc.price, 0)
        }
      })
    } else {
      totalPrice = state.orderDetail.skus.reduce((sum, sku) => sum + sku.price, 0)
    }

    return totalPrice
  },
  skusDiscount: (state) => state.orderDetail.skus.reduce((sum, sku) => sum + sku.discountPrice, 0),
  skusNetPrice: (state, getter) => {
    const skusNetPrice = getter.skusTotalPrice - getter.skusDiscount
    return skusNetPrice > 0 ? skusNetPrice : 0
  },
  codeDiscount: (state) => {
    const { discount } = state.orderDetail

    return discount > 0 ? discount : 0
  },
  shippingFee: (state) => state.orderDetail?.shipping?.fee || 0,
  netPrice: (state, getter) => {
    const netPrice = (getter.skusTotalPrice + getter.shippingFee) - getter.codeDiscount
    return netPrice > 0 ? netPrice : 0
  },
  orderPayments: (state) => state.orderDetail?.payments?.sort((a, b) => a.payOrder - b.payOrder) || [],
  orderDHLPackages: (state) => state.orderDetail?.dhlPackages || [],
  orderDHLAttachments: (state) => state.orderDetail?.dhlAttachments || [],
  productModalActive: (state) => state.productModalActive,
  isGotMember: (state) => state.isGotMember,
  isVerifiedCode: (state) => state.isVerifiedCode,
  currencyUnit: (state) => {
    if (state.orderDetail.channel === 'online_singapore') {
      return 'SGD'
    }

    if (
      state.orderDetail.channel === 'online_international'
      || state.orderDetail.channel === 'chatbot_international'
      || state.orderDetail.channel === 'international_manual'
    ) {
      return 'USD'
    }

    return 'THB'
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
