const state = {
  history: []
}

const actions = {
  addRouteHistory ({ commit, state }, payload) {
    if (state.history.length >= 5) {
      commit('SHIFT_HISTORY')
    }
    commit('PUSH_HISTORY', payload)
  }
}

const mutations = {
  SHIFT_HISTORY (state) {
    state.history.shift()
  },
  PUSH_HISTORY (state, payload) {
    state.history.push(payload)
  }
}

const getters = {
  routeHistory: (state) => {
    const routeHistory = new Array(...state.history)
    return routeHistory.reverse()
  },
  recentlyRoute: (state, thisGetters) => thisGetters.routeHistory[0] || null
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
