const state = {
  member: {}
}

const actions = {
  setMember ({ commit }, payload) {
    commit('SET_MEMBER', payload)
  },
  resetMember ({ commit }) {
    commit('RESET_MEMBER')
  }
}

const mutations = {
  SET_MEMBER (state, payload) {
    state.member = payload
  },
  RESET_MEMBER (state) {
    state.member = {}
  }
}

const getters = {
  member: (state) => state.member
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
