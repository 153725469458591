<template>
  <v-snackbar
    v-model="isSnackbar"
    :color="type"
    multi-line="multi-line"
    :timeout="timeout"
    top>
    <v-icon
      v-if="icon"
      class="mr-2"
      color="#ffffff">
      {{ icon }}
    </v-icon>
    <!-- eslint-disable-next-line vue/no-v-html -->
    <span v-html="message" />
    <template v-slot:action="{ attrs }">
      <v-btn
        v-bind="attrs"
        icon
        @click="closeSnackbar">
        <v-icon color="#ffffff">
          mdi-close-circle
        </v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true
    },
    type: {
      type: String,
      required: false,
      default: undefined
    },
    message: {
      type: String,
      required: true
    },
    timeout: {
      type: Number,
      required: false,
      default: 3000
    }
  },
  data () {
    return {
      isSnackbar: false
    }
  },
  computed: {
    icon () {
      let icon = 'mdi-information'
      if (this.type === 'success') {
        icon = 'mdi-checkbox-marked-circle'
      } else if (this.type === 'warning') {
        icon = 'mdi-alert-circle'
      } else if (this.type === 'error') {
        icon = 'mdi-alert'
      }
      return icon
    }
  },
  watch: {
    value (e) {
      this.isSnackbar = e
    },
    isSnackbar (e) {
      if (!e) {
        this.closeSnackbar()
      }
    }
  },
  created () {
    this.isSnackbar = this.value
  },
  methods: {
    closeSnackbar () {
      this.$emit('close', false)
    }
  }
}
</script>

<style scoped>

</style>
