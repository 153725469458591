export default {
  path: '/credit-note',
  component: () => import('@/views/creditNote/index.vue'),
  children: [
    {
      path: ':id',
      name: 'CreditNoteDetail',
      component: () => import('@/views/creditNote/pages/creditNoteDetail.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'area_manager',
          'marketing',
          'developer',
          'accounting_manager',
          'merchandising_planner',
          'merchandiser',
          'online_admin',
          'support',
          'warehouse_manager',
          'warehouse',
          'store_manager',
          'acting_assist_store_manager',
          'assist_store_manager',
          'inventory_and_costing',
          'sales_staff'
        ]
      }
    }
  ]
}
