import { getAuthToken } from '@/assets/js/Authentication'
import HttpRequest from './HttpRequest'

class StoreProvider extends HttpRequest {
  getStoreByToken () {
    this.setHeader(getAuthToken())
    return this.get('stores/info/token')
  }

  getStoreSetting () {
    this.setHeader(getAuthToken())
    return this.get('store-settings')
  }

  updateStore (formData) {
    this.setHeader(getAuthToken())
    return this.put(`stores/${formData.id}`, formData)
  }

  updateStoreSetting (formData) {
    this.setHeader(getAuthToken())
    return this.put('store-settings', formData)
  }
}

export default StoreProvider
