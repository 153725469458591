import middleware from './index'

function middlewarePipeline (context, middlewareText, index) {
  if (!middlewareText[index]) {
    return context.next
  }
  const nextMiddleware = middleware[middlewareText[index]]
  return () => {
    const nextPipeline = middlewarePipeline(
      context, middlewareText, index + 1
    )

    nextMiddleware({
      ...context,
      next: nextPipeline
    })
  }
}

export default middlewarePipeline
