<template>
  <v-container
    id="error-page"
    fluid>
    <v-card height="100%">
      <v-row
        align="center"
        justify="center">
        <v-col
          :cols="12"
          class="text-center">
          <h1 class="text-h1 mb-5">
            {{ errorCode }}
          </h1>
          <h3 class="text-h3 mb-5">
            {{ $t(`error.title-${errorCode}`) }}
          </h3>
          <p
            v-if="errorCode !== 404"
            class="text-Caption">
            {{ $t(`error.${errorCode}`) }}
          </p>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
export default {
  props: {
    errorCode: {
      type: Number,
      required: true
    }
  }
}
</script>

<style scoped>
#error-page,
#error-page .row {
  height: 100%;
}
</style>
