import { getAuthDecode, getRole } from '@/assets/js/Authentication'
import WarehouseProvider from '@/resources/WarehouseProvider'
import StoreProvider from '@/resources/StoreProvider'
import ShippingProvider from '@/resources/ShippingProvider'
import UserProvider from '@/resources/UserProvider'

const WarehouseService = new WarehouseProvider()
const StoreService = new StoreProvider()
const ShippingService = new ShippingProvider()
const UserService = new UserProvider()

const state = {
  pageReady: false,
  warehouse: [],
  userBranch: [],
  shipping: [],
  user: {},
  store: {},
  storeSetting: {}
}

const actions = {
  async initStore ({ dispatch, commit }) {
    const auth = getAuthDecode()
    if (auth?.userId) {
      await Promise.all([
        dispatch('getStoreInfo'),
        dispatch('getWarehouse'),
        dispatch('getShipping'),
        dispatch('initStoreSetting'),
        dispatch('getUserBranch', auth.userId)
      ])
    }
    commit('SET_PAGEREADY', true)
  },
  filterDefectWarehouse (_, warehouses) {
    const defectWarehouses = ['GW-DEF', 'MM-DEF']
    const defectRoles = ['warehouse_manager', 'management', 'developer', 'inventory_and_costing']
    const inDisputeWarehouses = ['GW-IDP', 'MM-IDP']
    const inDisputeRoles = ['developer', 'warehouse_manager', 'inventory_and_costing']
    const repairWarehouses = ['GW-RPR', 'MM-RPR']
    const repairRoles = ['warehouse_manager', 'management', 'developer']
    const userRole = getRole()
    const defectable = defectRoles.includes(userRole)
    const inDispute = inDisputeRoles.includes(userRole)
    const repair = repairRoles.includes(userRole)

    return warehouses.filter((v) => {
      if (defectable && defectWarehouses.includes(v.code)) {
        return true
      }

      if (defectWarehouses.includes(v.code)) {
        return false
      }

      if (inDispute && inDisputeWarehouses.includes(v.code)) {
        return true
      }

      if (inDisputeWarehouses.includes(v.code)) {
        return false
      }

      if (repair && repairWarehouses.includes(v.code)) {
        return true
      }

      if (repairWarehouses.includes(v.code)) {
        return false
      }

      return true
    })
  },
  async getWarehouse ({ commit, dispatch }) {
    try {
      const { data } = await WarehouseService.getWarehouse()

      const results = await dispatch('filterDefectWarehouse', data)

      commit('SET_WAREHOUSE', results)
    } catch (error) {
      console.error('getWarehouse', error)
    }
  },
  async getStoreInfo ({ commit }) {
    try {
      const { data } = await StoreService.getStoreByToken()
      commit('SET_STORE', data)
    } catch (error) {
      console.error('getStoreInfo', error)
    }
  },
  async initStoreSetting ({ commit }) {
    try {
      const { data } = await StoreService.getStoreSetting()
      commit('SET_STORE_SETTING', data)
    } catch (error) {
      console.error('initStoreSetting', error)
    }
  },
  async getShipping ({ dispatch }) {
    try {
      const { data } = await ShippingService.getShipping()
      dispatch('setShipping', data)
    } catch (error) {
      console.error('getShipping', error)
    }
  },
  async getUserBranch ({ dispatch }, payload) {
    try {
      const { data } = await UserService.getUserBranch(payload)

      const results = await dispatch('filterDefectWarehouse', data.map((v) => v.warehouse))

      dispatch('setUserBranch', results)
    } catch (error) {
      console.error('getUserBranch', error)
    }
  },
  setWarehouse ({ commit }, payload) {
    commit('SET_WAREHOUSE', payload)
  },
  setShipping ({ commit }, payload) {
    commit('SET_SHIPPING', payload)
  },
  setUserBranch ({ commit }, payload) {
    commit('SET_USER_BRANCH', payload)
  },
  resetStore ({ commit }) {
    commit('RESET_STORE')
  }
}

const mutations = {
  RESET_STORE () {
    state.warehouse = []
    state.shipping = []
    state.pageReady = false
  },
  SET_PAGEREADY (state, payload) {
    state.pageReady = payload
  },
  SET_STORE_SETTING (state, payload) {
    state.storeSetting = payload
  },
  SET_WAREHOUSE (state, payload) {
    state.warehouse = [
      {
        id: 0,
        name: 'Main',
        code: 'main'
      },
      ...payload
    ]
  },
  SET_SHIPPING (state, payload) {
    state.shipping = [...payload]
  },
  SET_USER_BRANCH (state, payload) {
    state.userBranch = [...payload]
  },
  SET_STORE (state, payload) {
    state.store = payload
  }
}

const getters = {
  warehouse: (state) => state.warehouse,
  pageReady: (state) => state.pageReady,
  store: (state) => state.store,
  storeSetting: (state) => state.storeSetting,
  linkBill: (state) => `https://${state.storeSetting.linkBill}`,
  mapWarehouse: (state) => {
    const noMainWarehouseRole = ['cashier', 'employ', 'no_rule']
    const results = []
    const userRole = getRole()

    if (userRole && !(noMainWarehouseRole.includes(userRole))) {
      results.push({
        id: 0,
        name: 'Main',
        code: 'main'
      })
    }

    results.push(...state.userBranch)

    return results
  },
  shipping: (state) => state.shipping,
  mapShipping: (state) => [
    ...state.shipping.map((r) => ({
      fee: r.price,
      providerName: r.providerName,
      method: r.method
    })),
    {
      fee: 0,
      providerName: 'POS',
      method: 'POS'
    }
  ],
  userBranch: (state, thisGetters) => {
    const userRole = getRole()

    if (userRole && userRole !== 'cashier' && userRole !== 'employ' && userRole !== 'no_rule') {
      return thisGetters.mapWarehouse
    }

    if (userRole) {
      return state.userBranch
    }

    return []
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
