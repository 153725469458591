const state = {
  snackbar: {
    message: '',
    type: 'success',
    timeout: 3000,
    canClose: true,
    value: false
  },
  modal: {
    confirmText: '',
    cancelText: '',
    message: '',
    title: '',
    value: false,
    confirmOnly: false,
    confirmType: '',
    onConfirm: null,
    onCancel: null
  },
  loading: {
    active: false,
    clickAble: true,
    message: 'This is message.'
  },
  errorPage: 0
}

const actions = {
  setErrorPage ({ commit }, payload) {
    commit('SET_ERROR_PAGE', payload)
  },
  tokenExpire ({ commit }, payload) {
    commit('SET_MODAL', {
      confirmText: 'TokenExpire',
      message: 'TokenExpire',
      title: 'TokenExpire',
      value: true,
      confirmOnly: true,
      confirmType: 'error',
      onConfirm: payload
    })
  },
  setLoading ({ commit }, payload) {
    commit('SET_LOADING', payload)
  },
  setSnackbar ({ commit }, payload) {
    commit('SET_SNACKBAR', payload)
  },
  setDarkMode ({ commit }, payload) {
    commit('SET_DARKMODE', payload)
  },
  setModal ({ commit }, payload) {
    commit('SET_MODAL', payload)
  }
}

const mutations = {
  SET_LOADING (state, payload) {
    state.loading.active = payload.active
    state.loading.clickAble = payload.clickAble
    state.loading.message = payload.message
  },
  SET_SNACKBAR (state, payload) {
    state.snackbar.message = payload.message || ''
    state.snackbar.type = payload.type || ''
    state.snackbar.value = payload.value || false
    state.snackbar.canClose = !!(payload.canClose === null || payload.canClose === undefined || payload.canClose === true)
    state.snackbar.timeout = payload.timeout || 3000
  },
  SET_MODAL (state, payload) {
    state.modal.confirmText = payload.confirmText || 'Agree'
    state.modal.cancelText = payload.cancelText || 'Disagree'
    state.modal.title = payload.title || null
    state.modal.value = payload.value || false
    state.modal.message = payload.message || ''
    state.modal.confirmOnly = payload.confirmOnly || false
    state.modal.confirmType = payload.confirmType === '' || payload.confirmType ? payload.confirmType : 'success'
    state.modal.cancelType = payload.cancelType === '' || payload.cancelType ? payload.cancelType : 'error'
    state.modal.onConfirm = payload.onConfirm ? payload.onConfirm : null
    state.modal.onCancel = payload.onCancel ? payload.onCancel : null
  },
  SET_ERROR_PAGE (state, payload) {
    state.errorPage = payload
  }
}

const getters = {
  snackbar: (state) => state.snackbar,
  loading: (state) => state.loading,
  modal: (state) => state.modal,
  errorPage: (state) => state.errorPage
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
