<template>
  <v-dialog
    v-model="active"
    persistent
    max-width="420">
    <v-card>
      <v-card-title class="headline">
        {{ title ? getText('modal.title', title) : '' }}
      </v-card-title>
      <v-card-text>
        <p class="white-space-pre">
          <!-- eslint-disable-next-line vue/no-v-html -->
          <span v-html="message ? getText('modal.message', message) : ''"></span>
        </p>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          v-if="!confirmOnly"
          :color="cancelType"
          text
          @click="cancel">
          {{ cancelText ? getText('modal.button', cancelText) : '' }}
        </v-btn>
        <v-btn
          :color="confirmType"
          text
          @click="confirm">
          {{ confirmText ? getText('modal.button', confirmText) : '' }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>

export default {
  props: {
    active: {
      type: Boolean,
      required: true,
      default: false
    },
    confirmText: {
      type: String,
      required: false,
      default: 'Agree'
    },
    cancelText: {
      type: String,
      required: false,
      default: 'Disagree'
    },
    title: {
      type: String,
      required: false,
      default: ''
    },
    message: {
      type: String,
      required: false,
      default: ''
    },
    confirmOnly: {
      type: Boolean,
      required: false,
      default: false
    },
    confirmType: {
      type: String,
      required: false,
      default: ''
    },
    cancelType: {
      type: String,
      required: false,
      default: ''
    }
  },
  methods: {
    getText (where, message) {
      const text = this.$t(`${where}.${message}`)

      if (text !== `${where}.${message}`) {
        return text
      }

      return message
    },
    confirm () {
      this.$emit('update:active', false)
      this.$emit('onConfirm', true)
    },
    cancel () {
      this.$emit('update:active', false)
      this.$emit('onCancel', true)
    }
  }
}
</script>

<style lang="scss" scoped>
  .white-space-pre > * {
    white-space: pre-line;
  }
</style>
