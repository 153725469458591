export default {
  path: '/prototype',
  component: () => import('@/views/prototype/index.vue'),
  children: [
    {
      path: 'attributes',
      component: () => import('@/views/prototypeAttributes/index.vue'),
      children: [
        {
          path: '',
          name: 'PrototypeAttribute',
          redirect: { name: 'FactoryManagement' }
        },
        {
          path: 'factory',
          name: 'FactoryManagement',
          component: () => import('@/views/prototypeAttributes/page/FactoryManagement.vue'),
          meta: {
            middleware: [
              'auth',
              'management',
              'developer',
              'designer',
              'merchandising_planner',
              'accounting_manager',
              'merchandiser',
              'support'
            ]
          }
        },
        {
          path: 'fabric-factory',
          name: 'FabricFactoryManagement',
          component: () => import('@/views/prototypeAttributes/page/FabricFactoryManagement.vue'),
          meta: {
            middleware: [
              'auth',
              'management',
              'developer',
              'designer',
              'merchandising_planner',
              'accounting_manager',
              'merchandiser',
              'support'
            ]
          }
        },
        {
          path: 'material-factory',
          name: 'MaterialFactoryManagement',
          component: () => import('@/views/prototypeAttributes/page/MaterialFactoryManagement.vue'),
          meta: {
            middleware: [
              'auth',
              'management',
              'developer',
              'designer',
              'merchandising_planner',
              'accounting_manager',
              'merchandiser',
              'support'
            ]
          }
        },
        {
          path: 'merchandiser',
          name: 'MerchandiserManagement',
          component: () => import('@/views/prototypeAttributes/page/MerchandiserManagement.vue'),
          meta: {
            middleware: [
              'auth',
              'management',
              'developer',
              'designer',
              'merchandising_planner',
              'accounting_manager',
              'merchandiser',
              'support'
            ]
          }
        },
        {
          path: 'designer',
          name: 'DesignerManagement',
          component: () => import('@/views/prototypeAttributes/page/DesignerManagement.vue'),
          meta: {
            middleware: [
              'auth',
              'management',
              'developer',
              'designer',
              'merchandising_planner',
              'accounting_manager',
              'merchandiser',
              'support'
            ]
          }
        },
        {
          path: 'graphics',
          name: 'GraphicManagement',
          component: () => import('@/views/prototypeAttributes/page/GraphicManagement.vue'),
          meta: {
            middleware: [
              'auth',
              'management',
              'developer',
              'designer',
              'merchandising_planner',
              'accounting_manager',
              'merchandiser',
              'support'
            ]
          }
        },
        {
          path: 'stylists',
          name: 'StylistManagement',
          component: () => import('@/views/prototypeAttributes/page/StylistManagement.vue'),
          meta: {
            middleware: [
              'auth',
              'management',
              'developer',
              'designer',
              'merchandising_planner',
              'accounting_manager',
              'merchandiser',
              'support'
            ]
          }
        },
        {
          path: 'sub-category',
          name: 'SubCategoryManagement',
          component: () => import('@/views/prototypeAttributes/page/SubCategoryManagement.vue'),
          meta: {
            middleware: [
              'auth',
              'management',
              'developer',
              'designer',
              'merchandising_planner',
              'accounting_manager',
              'merchandiser',
              'support'
            ]
          }
        }
      ]
    },
    {
      path: 'materials',
      name: 'MaterialsList',
      component: () => import('@/views/prototype/page/MaterialsList.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'developer',
          'designer',
          'merchandising_planner',
          'accounting_manager',
          'merchandiser',
          'support'
        ]
      }
    },
    {
      path: 'materials/new',
      name: 'NewMaterialForm',
      component: () => import('@/views/prototype/page/MaterialForm.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'developer',
          'designer',
          'merchandising_planner',
          'accounting_manager',
          'merchandiser',
          'support'
        ]
      }
    },
    {
      path: 'materials/edit/:id',
      name: 'EditMaterialForm',
      component: () => import('@/views/prototype/page/MaterialForm.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'developer',
          'designer',
          'merchandising_planner',
          'accounting_manager',
          'merchandiser',
          'support'
        ]
      }
    },
    {
      path: 'fabrics',
      name: 'FabricsList',
      component: () => import('@/views/prototype/page/FabricsList.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'developer',
          'designer',
          'merchandising_planner',
          'accounting_manager',
          'merchandiser',
          'support'
        ]
      }
    },
    {
      path: 'fabrics/new',
      name: 'NewFabricForm',
      component: () => import('@/views/prototype/page/FabricForm.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'developer',
          'designer',
          'merchandising_planner',
          'accounting_manager',
          'merchandiser',
          'support'
        ]
      }
    },
    {
      path: 'fabrics/edit/:id',
      name: 'EditFabricForm',
      component: () => import('@/views/prototype/page/FabricForm.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'developer',
          'designer',
          'merchandising_planner',
          'accounting_manager',
          'merchandiser',
          'support'
        ]
      }
    },
    {
      path: 'sketch',
      name: 'SketchList',
      component: () => import('@/views/prototype/page/SketchList.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'developer',
          'designer',
          'inventory_and_costing',
          'merchandising_planner',
          'accounting_manager',
          'merchandiser',
          'support'
        ]
      }
    },
    {
      path: 'sketch-old',
      name: 'SketchListOld',
      component: () => import('@/views/prototype/page/SketchListOld.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'developer',
          'designer',
          'inventory_and_costing',
          'merchandising_planner',
          'accounting_manager',
          'merchandiser',
          'support'
        ]
      }
    },
    {
      path: 'sketch/create',
      name: 'CreateSketch',
      component: () => import('@/views/prototype/page/CreateSketch.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'developer',
          'designer',
          'inventory_and_costing',
          'merchandising_planner',
          'accounting_manager',
          'merchandiser',
          'support'
        ]
      }
    },
    {
      path: 'sketch/create-old',
      name: 'CreateSketchOld',
      component: () => import('@/views/prototype/page/CreateSketchOld.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'developer',
          'designer',
          'inventory_and_costing',
          'merchandising_planner',
          'accounting_manager',
          'merchandiser',
          'support'
        ]
      }
    },
    {
      path: 'sketch/edit/:id',
      name: 'EditSketch',
      component: () => import('@/views/prototype/page/CreateSketch.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'developer',
          'designer',
          'inventory_and_costing',
          'merchandising_planner',
          'accounting_manager',
          'merchandiser',
          'support'
        ]
      }
    },
    {
      path: 'sketch/edit-old/:id',
      name: 'EditSketchOld',
      component: () => import('@/views/prototype/page/CreateSketchOld.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'developer',
          'designer',
          'inventory_and_costing',
          'merchandising_planner',
          'accounting_manager',
          'merchandiser',
          'support'
        ]
      }
    },
    {
      path: 'sketch/export-pdf',
      name: 'ExportSketch',
      component: () => import('@/views/prototype/page/ExportSketch.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'developer',
          'designer',
          'merchandising_planner',
          'accounting_manager',
          'merchandiser',
          'support'
        ],
        layout: 'Blank'
      }
    },
    {
      path: 'manufacture/export-fabric-order',
      name: 'ExportFabricOrder',
      component: () => import('@/views/prototype/page/ExportFabricOrder.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'developer',
          'designer',
          'merchandising_planner',
          'accounting_manager',
          'merchandiser',
          'support'
        ],
        layout: 'Blank'
      }
    },
    {
      path: 'manufacture/export-pdf',
      name: 'ExportManufacture',
      component: () => import('@/views/prototype/page/ExportManufacture.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'developer',
          'designer',
          'merchandising_planner',
          'accounting_manager',
          'merchandiser',
          'support'
        ],
        layout: 'Blank'
      }
    },
    {
      path: 'packing-list/export-pdf',
      name: 'ExportPackingList',
      component: () => import('@/views/prototype/page/ExportPackingList.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'developer',
          'designer',
          'inventory_and_costing',
          'merchandising_planner',
          'accounting_manager',
          'merchandiser',
          'support'
        ],
        layout: 'Blank'
      }
    },
    {
      path: 'order/edit/:id',
      name: 'EditOrderForm',
      component: () => import('@/views/prototype/page/EditOrderForm.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'developer',
          'designer',
          'inventory_and_costing',
          'merchandising_planner',
          'accounting_manager',
          'merchandiser',
          'support'
        ]
      }
    },
    {
      path: 'order/edit-old/:id',
      name: 'EditOrderFormOld',
      component: () => import('@/views/prototype/page/EditOrderFormOld.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'developer',
          'designer',
          'inventory_and_costing',
          'merchandising_planner',
          'accounting_manager',
          'merchandiser',
          'support'
        ]
      }
    },
    {
      path: 'order',
      name: 'OrderFormList',
      component: () => import('@/views/prototype/page/OrderFormList.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'developer',
          'designer',
          'inventory_and_costing',
          'merchandising_planner',
          'accounting_manager',
          'merchandiser',
          'support'
        ]
      }
    },
    {
      path: 'order-old',
      name: 'OrderFormListOld',
      component: () => import('@/views/prototype/page/OrderFormListOld.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'developer',
          'designer',
          'inventory_and_costing',
          'merchandising_planner',
          'accounting_manager',
          'merchandiser',
          'support'
        ]
      }
    },
    {
      path: 'product-mapping',
      name: 'ProductMappingList',
      component: () => import('@/views/prototype/page/ProductMappingList.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'developer',
          'designer',
          'inventory_and_costing',
          'merchandising_planner',
          'accounting_manager',
          'merchandiser',
          'support'
        ]
      }
    },
    {
      path: 'product-mapping-old',
      name: 'ProductMappingListOld',
      component: () => import('@/views/prototype/page/ProductMappingListOld.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'developer',
          'designer',
          'inventory_and_costing',
          'merchandising_planner',
          'accounting_manager',
          'merchandiser',
          'support'
        ]
      }
    },
    {
      path: 'product-mapping/:id',
      name: 'ProductMappingDetail',
      component: () => import('@/views/prototype/page/ProductMapping.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'developer',
          'designer',
          'merchandising_planner',
          'accounting_manager',
          'merchandiser',
          'support'
        ]
      }
    },
    {
      path: 'product-mapping-old/:id',
      name: 'ProductMappingDetailOld',
      component: () => import('@/views/prototype/page/ProductMappingOld.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'developer',
          'designer',
          'merchandising_planner',
          'accounting_manager',
          'merchandiser',
          'support'
        ]
      }
    },
    {
      path: 'manufacture',
      name: 'ManufactureList',
      component: () => import('@/views/prototype/page/ManufactureList.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'developer',
          'designer',
          'inventory_and_costing',
          'merchandising_planner',
          'accounting_manager',
          'merchandiser',
          'support'
        ]
      }
    },
    {
      path: 'manufacture-old',
      name: 'ManufactureListOld',
      component: () => import('@/views/prototype/page/ManufactureListOld.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'developer',
          'designer',
          'inventory_and_costing',
          'merchandising_planner',
          'accounting_manager',
          'merchandiser',
          'support'
        ]
      }
    },
    {
      path: 'tracking',
      name: 'TrackingList',
      component: () => import('@/views/prototype/page/TrackingList.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'developer',
          'designer',
          'inventory_and_costing',
          'merchandising_planner',
          'accounting_manager',
          'merchandiser',
          'support'
        ]
      }
    },
    {
      path: 'margin-calculate',
      name: 'MarginCalculate',
      component: () => import('@/views/prototype/page/MarginCalculate.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'developer',
          'designer',
          'inventory_and_costing',
          'merchandising_planner',
          'accounting_manager',
          'merchandiser',
          'support'
        ]
      }
    },
    {
      path: 'fabric-orders',
      name: 'FabricOrdersList',
      component: () => import('@/views/prototype/page/FabricOrdersList.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'developer',
          'designer',
          'inventory_and_costing',
          'merchandising_planner',
          'accounting_manager',
          'merchandiser',
          'support'
        ]
      }
    }
  ]
}
