export default {
  path: '/web-management',
  component: () => import('@/views/webManagement/index.vue'),
  children: [
    {
      path: '',
      name: 'WebManagement',
      component: () => import('@/views/webManagement/pages/WebManagement.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'accounting_manager',
          'marketing',
          'developer',
          'support'
        ]
      }
    }
  ]
}
