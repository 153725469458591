import { getAuthToken } from '@/assets/js/Authentication'
import HttpRequest from './HttpRequest'

class WarehouseProvider extends HttpRequest {
  getWarehouse (query) {
    this.setHeader(getAuthToken())
    return this.get('/warehouses', query)
  }

  getWarehouseByPaginate (paginate) {
    this.setHeader(getAuthToken())
    return this.getByPagination('/warehouses', paginate)
  }

  deleteWarehouse (id) {
    this.setHeader(getAuthToken())
    return this.delete(`/warehouses?id=${id}`)
  }

  createWarehouse (payload) {
    this.setHeader(getAuthToken())
    return this.post('/warehouses', payload)
  }

  updateWarehouse (id, payload) {
    this.setHeader(getAuthToken())
    return this.put(`/warehouses/${id}`, payload)
  }

  updatePriority (payloads) {
    this.setHeader(getAuthToken())
    return this.patch('/warehouses/priority', payloads)
  }

  updateStatus (payloads) {
    this.setHeader(getAuthToken())
    return this.patch('/warehouses/status', payloads)
  }
}

export default WarehouseProvider
