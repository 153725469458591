<template>
  <div
    v-show="loading.active"
    :class="classOverlay"
    class="main-content overlay"
    @click="canClose()">
    <v-progress-circular
      :size="200"
      :width="10"
      color="light-blue"
      indeterminate>
      {{ loading.message }}
    </v-progress-circular>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      loading: 'Components/loading'
    }),
    classOverlay () {
      return this.loading.clickAble ? 'cs-pointer' : ''
    }
  },
  methods: {
    ...mapActions({
      setLoading: 'Components/setLoading'
    }),
    close () {
      this.setLoading({
        active: false,
        clickAble: this.loading.clickAble,
        message: this.loading.message
      })
    },
    checkClass () {

    },
    canClose () {
      if (this.loading.clickAble) {
        this.close()
      }
    }
  }
}
</script>

<style scoped>
.overlay {
  background-color: rgba(0,0,0,0.5);
  height: 100%;
  width: 100%;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  position: fixed;
}
.cs-pointer {
  cursor: pointer;
}
</style>
