export default {
  path: '/store-reports',
  component: () => import('@/views/storeReports/index.vue'),
  children: [
    {
      path: '/stock-aging',
      name: 'StockAgingReport',
      component: () => import('@/views/storeReports/pages/StockAgingReport.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'area_manager',
          'marketing',
          'developer',
          'accounting_manager',
          'accounting',
          'store_manager',
          'assist_store_manager',
          'support',
          'warehouse_manager',
          'merchandising_planner',
          'inventory_and_costing'
        ]
      }
    },
    {
      path: '/sales-products',
      name: 'SalesProductsReport',
      component: () => import('@/views/storeReports/pages/SalesProductsReport.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'area_manager',
          'marketing',
          'developer',
          'accounting_manager',
          'accounting',
          'store_manager',
          'assist_store_manager',
          'support',
          'warehouse_manager',
          'merchandising_planner',
          'inventory_and_costing'
        ]
      }
    },
    {
      path: '/best-seller',
      name: 'BestSellerReport',
      component: () => import('@/views/storeReports/pages/BestSellerReport.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'area_manager',
          'marketing',
          'developer',
          'accounting_manager',
          'accounting',
          'store_manager',
          'assist_store_manager',
          'support',
          'warehouse_manager',
          'merchandising_planner',
          'inventory_and_costing'
        ]
      }
    }
  ]
}
