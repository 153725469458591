<template>
  <validation-provider
    v-slot="{ errors }"
    :name="$attrs.label"
    :rules="rules">
    <v-skeleton-loader
      :loading="loading"
      :height="150"
      type="list-item-three-line">
      <v-textarea
        v-model="innerValue"
        :error-messages="errors"
        v-bind="$attrs"
        v-on="$listeners" />
    </v-skeleton-loader>
  </validation-provider>
</template>

<script>
export default {
  props: {
    rules: {
      type: [Object, String],
      default: ''
    },
    value: {
      type: null,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      innerValue: null
    }
  },
  watch: {
    innerValue (newVal) {
      this.$emit('input', newVal)
    },
    value (newVal) {
      this.innerValue = newVal
    }
  },
  created () {
    if (this.value) {
      this.innerValue = this.value
    }
  }
}
</script>
