export default {
  path: '/pms',
  component: () => import('@/views/pms/index.vue'),
  children: [
    {
      path: '',
      name: 'PerformanceReview',
      component: () => import('@/views/pms/pages/PerformanceReview.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'area_manager',
          'accounting_manager',
          'developer',
          'vm'
        ]
      }
    },
    {
      path: 'performance-report',
      name: 'PerformanceReport',
      component: () => import('@/views/pms/pages/PerformanceReport.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'accounting_manager',
          'developer'
        ]
      }
    },
    {
      path: 'employees-list',
      name: 'EmployeesList',
      component: () => import('@/views/pms/pages/EmployeesList.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'area_manager',
          'accounting_manager',
          'office_admin',
          'developer',
          'support'
        ]
      }
    },
    {
      path: 'employee-detail/:userId',
      name: 'EmployeeDetail',
      component: () => import('@/views/pms/pages/EmployeeDetail.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'area_manager',
          'accounting_manager',
          'office_admin',
          'developer',
          'support'
        ]
      }
    },
    {
      path: 'broadcast-message',
      name: 'BroadcastMessage',
      component: () => import('@/views/pms/pages/BroadcastMessage.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'area_manager',
          'accounting_manager',
          'developer',
          'vm'
        ]
      }
    },
    {
      path: 'daily-briefs',
      name: 'DailyBriefs',
      component: () => import('@/views/pms/pages/DailyBriefs.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'accounting_manager',
          'area_manager',
          'developer',
          'vm'
        ]
      }
    },
    {
      path: 'settings',
      name: 'PerformanceReportSettings',
      component: () => import('@/views/pms/pages/Settings.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'accounting_manager',
          'developer',
          'vm'
        ]
      }
    }
  ]
}
