export default {
  path: '/purchases',
  component: () => import('@/views/purchases/index.vue'),
  children: [
    {
      path: 'vendors',
      name: 'PurchasesVendors',
      component: () => import('@/views/purchases/pages/Vendors.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'developer',
          'designer',
          'merchandising_planner',
          'accounting_manager',
          'accounting',
          'merchandiser',
          'support'
        ]
      }
    },
    {
      path: 'vendors/create',
      name: 'PurchasesVendorCreate',
      component: () => import('@/views/purchases/pages/VendorForm.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'developer',
          'designer',
          'merchandising_planner',
          'accounting_manager',
          'accounting',
          'merchandiser',
          'support'
        ]
      }
    },
    {
      path: 'vendors/:id',
      name: 'PurchasesVendorEdit',
      component: () => import('@/views/purchases/pages/VendorForm.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'developer',
          'designer',
          'merchandising_planner',
          'accounting_manager',
          'accounting',
          'merchandiser',
          'support'
        ]
      }
    },
    {
      path: 'items',
      name: 'PurchasesItems',
      component: () => import('@/views/purchases/pages/Items.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'developer',
          'designer',
          'inventory_and_costing',
          'merchandising_planner',
          'accounting_manager',
          'accounting',
          'merchandiser',
          'support'
        ]
      }
    },
    {
      path: 'items/create',
      name: 'PurchasesItemCreate',
      component: () => import('@/views/purchases/pages/ItemForm.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'developer',
          'designer',
          'merchandising_planner',
          'accounting_manager',
          'accounting',
          'merchandiser',
          'support'
        ]
      }
    },
    {
      path: 'items/:id',
      name: 'PurchasesItemEdit',
      component: () => import('@/views/purchases/pages/ItemForm.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'developer',
          'designer',
          'merchandising_planner',
          'accounting_manager',
          'accounting',
          'merchandiser',
          'support'
        ]
      }
    },
    {
      path: 'items/:id/stock-movement',
      name: 'PurchasesItemStockMovement',
      component: () => import('@/views/purchases/pages/ItemStockMovement.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'developer',
          'designer',
          'merchandising_planner',
          'accounting_manager',
          'accounting',
          'merchandiser',
          'support'
        ]
      }
    },
    {
      path: 'items/:id/stock-reserve',
      name: 'PurchasesItemStockReserve',
      component: () => import('@/views/purchases/pages/ItemStockReserve.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'developer',
          'designer',
          'merchandising_planner',
          'accounting_manager',
          'accounting',
          'merchandiser',
          'support'
        ]
      }
    },
    {
      path: 'orders',
      name: 'PurchasesOrders',
      component: () => import('@/views/purchases/pages/Orders.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'developer',
          'designer',
          'inventory_and_costing',
          'merchandising_planner',
          'accounting_manager',
          'accounting',
          'merchandiser',
          'support'
        ]
      }
    },
    {
      path: 'finished-goods-orders',
      name: 'PurchasesFinishedGoodsOrders',
      component: () => import('@/views/purchases/pages/GoodsOrders.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'developer',
          'designer',
          'inventory_and_costing',
          'merchandising_planner',
          'accounting_manager',
          'accounting',
          'merchandiser',
          'support'
        ]
      }
    },
    {
      path: 'orders/create',
      name: 'PurchasesOrderCreate',
      component: () => import('@/views/purchases/pages/OrderForm.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'developer',
          'designer',
          'merchandising_planner',
          'accounting_manager',
          'accounting',
          'merchandiser',
          'support'
        ]
      }
    },
    {
      path: 'orders/:id',
      name: 'PurchasesOrderEdit',
      component: () => import('@/views/purchases/pages/OrderForm.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'developer',
          'designer',
          'merchandising_planner',
          'accounting_manager',
          'accounting',
          'merchandiser',
          'support'
        ]
      }
    },
    {
      path: 'orders/:id/pdf',
      name: 'PurchasesOrderPdf',
      component: () => import('@/views/purchases/pages/OrderFormPdf.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'developer',
          'designer',
          'inventory_and_costing',
          'merchandising_planner',
          'accounting_manager',
          'accounting',
          'merchandiser',
          'support'
        ],
        layout: 'Blank'
      }
    },
    {
      path: 'assembly-orders',
      name: 'PurchasesAssemblyOrders',
      component: () => import('@/views/purchases/pages/AssemblyOrders.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'developer',
          'designer',
          'inventory_and_costing',
          'merchandising_planner',
          'accounting_manager',
          'accounting',
          'merchandiser',
          'support'
        ]
      }
    },
    {
      path: 'assembly-orders/create',
      name: 'PurchasesAssemblyOrderCreate',
      component: () => import('@/views/purchases/pages/AssemblyOrderForm.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'developer',
          'designer',
          'merchandising_planner',
          'accounting_manager',
          'accounting',
          'merchandiser',
          'support'
        ]
      }
    },
    {
      path: 'assembly-orders/:id',
      name: 'PurchasesAssemblyOrderEdit',
      component: () => import('@/views/purchases/pages/AssemblyOrderForm.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'developer',
          'designer',
          'merchandising_planner',
          'accounting_manager',
          'accounting',
          'merchandiser',
          'support'
        ]
      }
    },
    {
      path: 'assembly-bom',
      name: 'PurchasesAssemblyBOM',
      component: () => import('@/views/purchases/pages/AssemblyBOM.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'developer',
          'designer',
          'inventory_and_costing',
          'merchandising_planner',
          'accounting_manager',
          'accounting',
          'merchandiser',
          'support'
        ]
      }
    },
    {
      path: 'assembly-bom/create',
      name: 'PurchasesAssemblyBOMCreate',
      component: () => import('@/views/purchases/pages/AssemblyBOMForm.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'developer',
          'designer',
          'merchandising_planner',
          'accounting_manager',
          'accounting',
          'merchandiser',
          'support'
        ]
      }
    },
    {
      path: 'assembly-bom/:id',
      name: 'PurchasesAssemblyBOMEdit',
      component: () => import('@/views/purchases/pages/AssemblyBOMForm.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'developer',
          'designer',
          'merchandising_planner',
          'accounting_manager',
          'accounting',
          'merchandiser',
          'support'
        ]
      }
    },
    {
      path: 'customers',
      name: 'PurchasesCustomers',
      component: () => import('@/views/purchases/pages/Customers.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'developer',
          'designer',
          'merchandising_planner',
          'accounting_manager',
          'accounting',
          'merchandiser',
          'support'
        ]
      }
    },
    {
      path: 'customers/create',
      name: 'PurchasesCustomerCreate',
      component: () => import('@/views/purchases/pages/CustomerForm.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'developer',
          'designer',
          'merchandising_planner',
          'accounting_manager',
          'accounting',
          'merchandiser',
          'support'
        ]
      }
    },
    {
      path: 'customers/:id',
      name: 'PurchasesCustomerEdit',
      component: () => import('@/views/purchases/pages/CustomerForm.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'developer',
          'designer',
          'merchandising_planner',
          'accounting_manager',
          'accounting',
          'merchandiser',
          'support'
        ]
      }
    },
    {
      path: 'uom',
      name: 'PurchasesUOM',
      component: () => import('@/views/purchases/pages/UOM.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'developer',
          'designer',
          'merchandising_planner',
          'accounting_manager',
          'accounting',
          'merchandiser',
          'support'
        ]
      }
    },
    {
      path: 'uom/create',
      name: 'PurchasesUOMCreate',
      component: () => import('@/views/purchases/pages/UOMForm.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'developer',
          'designer',
          'merchandising_planner',
          'accounting_manager',
          'accounting',
          'merchandiser',
          'support'
        ]
      }
    },
    {
      path: 'uom/:id',
      name: 'PurchasesUOMEdit',
      component: () => import('@/views/purchases/pages/UOMForm.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'developer',
          'designer',
          'merchandising_planner',
          'accounting_manager',
          'accounting',
          'merchandiser',
          'support'
        ]
      }
    }
  ]
}
