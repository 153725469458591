<template>
  <!-- eslint-disable vue/no-v-html -->
  <div
    class="svg-icon"
    v-html="require(`!!html-loader!../../public/svg/${svgName}.svg`)"
  />
</template>

<script>
// note that if svg file has multiple path ,cant change fill color
export default {
  props: {
    svgName: {
      type: String,
      required: true
    },
    width: {
      type: String,
      required: false,
      default: undefined
    },
    height: {
      type: String,
      required: false,
      default: undefined
    },
    addClass: {
      type: String,
      required: false,
      default: undefined
    },
    color: {
      type: String,
      required: false,
      default: undefined
    },
    original: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      path: ''
    }
  },
  mounted () {
    if (!this.original) {
      const vm = this
      vm.init()
      // eslint-disable-next-line no-shadow, no-unused-vars
      vm.$watch((vm) => [vm.svgName, vm.addClass, vm.width, vm.height].join(), (val) => {
        vm.init()
      })
    }
  },
  methods: {
    init () {
      if (this.$el.querySelector('defs')) {
        this.$el.querySelector('defs').outerHTML = this.$el.querySelector('defs').innerHTML
        if (this.$el.querySelector('g')) {
          this.$el.querySelector('g').remove()
        }
      }
      if (this.$el.querySelectorAll('path').length === 1) {
        this.$el.querySelector('path').removeAttribute('fill')
      }
      // if have class
      const addClass = this.addClass ? this.addClass.trim() : ''
      if (addClass) {
        this.$el.firstChild.classList = []
        // default from class .svg
        if (this.$el.querySelectorAll('path').length === 1) {
          this.$el.firstChild.classList.add(this.$style.svg)
        }
        this.$el.firstChild.classList.add(...addClass.split(' '))
      }
      // width, height
      this.$el.firstChild.style.height = this.height ? this.height : this.$el.firstChild.getAttribute('height')
      this.$el.firstChild.style.width = this.width ? this.width : this.$el.firstChild.getAttribute('width')

      // if path more than one set color by stroke variable
      if (this.color) {
        // eslint-disable-next-line no-restricted-syntax
        for (const path of this.$el.querySelectorAll('path')) {
          if (path.getAttribute('stroke')) {
            path.setAttribute('stroke', this.color)
          } else {
            path.setAttribute('fill', this.color)
          }
        }
      }
      // this.$el.firstChild.clientHeight
      // this.$el.firstChild.clientWidth
    }
  }
}
</script>

<style module>
.svg {
  fill: currentColor;
}
</style>
