<template>
  <v-menu
    v-model="datePickerMenu"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="290px">
    <template #activator="{ on }">
      <validation-provider
        v-slot="{ errors }"
        :name="label"
        :rules="rules">
        <v-text-field
          v-model="dateText"
          :label="label"
          :append-icon="appendIcon"
          readonly
          :error-messages="errors"
          v-bind="$attrs"
          v-on="on"
          @click:append="clearValue()" />
      </validation-provider>
    </template>
    <v-date-picker
      v-model="date"
      color="secondary"
      class="elevation-3"
      :type="type">
      <v-spacer />
      <v-btn
        text
        small
        @click="datePickerClose()">
        {{ $t('fields.close') }}
      </v-btn>
      <v-btn
        text
        small
        @click="datePickerSubmit()">
        {{ $t('fields.submit') }}
      </v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
export default {
    props: {
        rules: {
            type: [Object, String],
            default: ''
        },
        value: {
            type: String,
            default: ''
        },
        label: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: 'date'
        },
        clearable: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            datePickerMenu: false,
            date: '',
            dateText: ''
        }
    },
    computed: {
        appendIcon () {
            if (this.clearable && this.value) {
                return 'mdi-close'
            }

            if (this.filled) {
                return 'mdi-calendar'
            }

            return ''
        }
    },
    watch: {
        value () {
            this.initDefaultDate()
        }
    },
    mounted () {
        this.initDefaultDate()
    },
    methods: {
        initDefaultDate () {
            if (this.value && this.type === 'month') {
                this.date = this.$dayjs(this.value).format('YYYY-MM')
                this.dateText = this.$dayjs(this.value).format('MMMM YYYY')
            } else if (this.type === 'month') {
                this.date = ''
                this.dateText = ''
            } else if (this.value) {
                this.date = this.$dayjs(this.value).format('YYYY-MM-DD')
                this.dateText = this.$dayjs(this.value).format('D MMMM YYYY')
            } else {
                this.date = ''
                this.dateText = ''
            }
        },
        datePickerSubmit () {
            if (this.type === 'month') {
                this.dateText = this.$dayjs(this.date).format('MMMM YYYY')
                this.$emit('input', this.$dayjs(this.date).format('YYYY-MM'))
            } else {
                this.dateText = this.$dayjs(this.date).format('D MMMM YYYY')
                this.$emit('input', this.$dayjs(this.date).format('YYYY-MM-DD'))
            }

            this.datePickerMenu = false
        },
        datePickerClose () {
            this.datePickerMenu = false
            this.initDefaultDate()
        },
        clearValue () {
            this.date = ''
            this.dateText = ''
            this.$emit('input', '')
        }
    }
}
</script>

<style>
.date-picker-input .v-input__control {
    min-height: 28px !important;
}

.date-picker-input .v-input__prepend-outer {
    margin: auto 9px auto 0 !important;
}

.date-picker-input .v-input {
    justify-content: center !important;
}
</style>
