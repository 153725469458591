export default {
  path: '/notes',
  component: () => import('@/views/notes/index.vue'),
  children: [
    {
      path: 'delivery-note',
      name: 'DeliveryNote',
      component: () => import('@/views/notes/page/DeliveryNote.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'creative',
          'area_manager',
          'marketing',
          'developer',
          'merchandising_planner',
          'merchandiser',
          'online_admin',
          'support',
          'inventory_and_costing',
          'warehouse_manager',
          'warehouse',
          'store_manager',
          'acting_assist_store_manager',
          'accounting_manager',
          'accounting',
          'vm',
          'assist_store_manager',
          'sales_staff'
        ],
        layout: 'Blank'
      }
    },
    {
      path: 'withdraw-note',
      name: 'WithdrawNote',
      component: () => import('@/views/notes/page/WithdrawNote.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'creative',
          'area_manager',
          'marketing',
          'developer',
          'merchandising_planner',
          'accounting_manager',
          'accounting',
          'merchandiser',
          'online_admin',
          'support',
          'inventory_and_costing',
          'warehouse_manager',
          'warehouse',
          'store_manager',
          'vm',
          'acting_assist_store_manager',
          'assist_store_manager',
          'sales_staff'
        ],
        layout: 'Blank'
      }
    }
  ]
}
