<template>
  <v-app-bar
    app
    dense
    :class="{ 'app-bar-mobile': isMobile }">
    <v-app-bar-nav-icon @click="clickNavbarIcon()" />

    <v-breadcrumbs
      class="pl-3 py-2"
      :items="breadcrumbs" />

    <v-spacer />

    <v-btn
      class="mr-2"
      color="secondary"
      outlined
      small
      @click="changeLocale()">
      {{ $i18n.locale }}
    </v-btn>

    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="user-avatar"
          icon
          v-bind="attrs"
          width="36"
          height="36"
          v-on="on">
          <v-avatar
            min-width="36"
            width="36"
            height="36">
            <img
              v-if="userData && userData.picture"
              :src="userData.picture"
              alt="user-avatar">
            <v-icon
              v-else
              dark>
              mdi-account-circle
            </v-icon>
          </v-avatar>
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="(item, index) in items"
          :key="index"
          dense
          link
          :to="item.to">
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
        <v-divider />
        <v-list-item
          dense
          @click="setDarkmode()">
          <v-list-item-icon>
            <v-icon> {{ iconTheme }} </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t(`menu.sidebar.${nameTheme}`) }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          dense
          link
          to="/logout">
          <v-list-item-icon>
            <v-icon> mdi-logout </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t(`menu.sidebar.Logout`) }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import { getAuthDecode } from '@/assets/js/Authentication'

export default {
  props: {
    value: {
      type: Boolean,
      required: true
    },
    breadcrumbs: {
      type: Array,
      required: false,
      default: () => []
    },
    windowWidth: {
      type: Number,
      required: true
    },
    mini: {
      type: Boolean,
      required: false,
      default: false
    },
    darkMode: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      items: []
    }
  },
  computed: {
    userData () {
      return getAuthDecode()
    },
    iconTheme () {
      return this.$vuetify.theme.dark ? 'mdi-white-balance-sunny' : 'mdi-moon-waning-crescent'
    },
    nameTheme () {
      return this.$vuetify.theme.dark ? 'LightMode' : 'DarkMode'
    },
    isMobile () {
      return this.windowWidth < 960
    }
  },
  methods: {
    changeLocale () {
      this.$i18n.locale = (this.$i18n.locale === 'en') ? 'th' : 'en'
    },
    clickNavbarIcon () {
      if (this.isMobile) {
        this.update(true)
      } else {
        this.setMini()
      }
    },
    update (value) {
      this.$emit('input', value)
    },
    setMini () {
      this.$emit('update:mini', !this.mini)
    },
    setDarkmode () {
      this.$emit('darkMode', !this.darkMode)
    }
  }
}
</script>

<style>
#select-warehouse,
#select-warehouse .v-input {
  display: flex;
  align-items: center;
}
#select-warehouse .v-input {
  width: 150px;
  margin-bottom: -5px;
}
#select-warehouse .v-text-field__details {
  display: none;
}
button.user-avatar {
  margin-right: 0 !important;
}
button.user-avatar i.v-icon.mdi-account-circle {
  width: 36px;
  height: 36px;
  font-size: 36px;
}
@media screen and (max-width: 767px) {
  #select-warehouse {
    width: 100%;
  }
}
</style>
