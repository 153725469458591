import Vue from 'vue'
import debounce from 'lodash/debounce'

Vue.prototype.$keypressNumber = (evt) => {
  const event = (evt) || window.event
  const charCode = (event.which) ? event.which : event.keyCode
  if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
    event.preventDefault()
    return false
  }
  return true
}

Vue.prototype.$keypressNumberNoDecimal = (evt) => {
  const event = (evt) || window.event
  const charCode = (event.which) ? event.which : event.keyCode
  if ((charCode > 31 && (charCode < 48 || charCode > 57)) || charCode === 46) {
    event.preventDefault()
    return false
  }
  return true
}

Vue.prototype.$keypressTelInput = (evt) => {
  const event = (evt) || window.event
  const charCode = (event.which) ? event.which : event.keyCode
  if (((charCode > 31 && (charCode < 48 || charCode > 57)) || charCode === 46) && charCode !== 43) {
    event.preventDefault()
    return false
  }
  return true
}

Vue.prototype.$debounce = debounce
