export default {
  path: '/tax-invoice',
  component: () => import('@/views/taxInvoice/index.vue'),
  children: [
    {
      path: '',
      name: 'TaxInvoice',
      component: () => import('@/views/taxInvoice/page/TaxInvoiceList.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'area_manager',
          'developer',
          'online_admin',
          'support',
          'accounting_manager',
          'store_manager',
          'acting_assist_store_manager',
          'assist_store_manager',
          'sales_staff'
        ]
      }
    }
  ]
}
