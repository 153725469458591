export default {
  path: '/dashboard',
  component: () => import('@/views/dashboard/index.vue'),
  children: [
    {
      path: '',
      name: 'Dashboard',
      component: () => import('@/views/dashboard/page/Dashboard.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'area_manager',
          'marketing',
          'developer',
          'merchandising_planner',
          'accounting_manager',
          'accounting'
        ]
      }
    },
    {
      path: 'new-dashboard',
      name: 'NewDashboard',
      component: () => import('@/views/dashboardNew/pages/NewDashboard.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'area_manager',
          'marketing',
          'developer',
          'merchandising_planner',
          'accounting_manager',
          'accounting'
        ]
      }
    },
    {
      path: '/crm',
      name: 'CrmDashboard',
      component: () => import('@/views/dashboard/page/CrmDashboard.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'area_manager',
          'accounting_manager',
          'marketing',
          'developer',
          'merchandising_planner'
        ]
      }
    },
    {
      path: '/visitors',
      name: 'VisitorsDashboard',
      component: () => import('@/views/dashboard/page/VisitorsDashboard.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'area_manager',
          'marketing',
          'developer',
          'merchandising_planner'
        ]
      }
    }
  ]
}
