export default ({ next, auth, store, skip }) => {
  const data = auth.getAuthDecode()
  if (data && data.role === 'sales_staff') {
    if (store.getters['Components/errorPage']) {
      store.dispatch('Components/setErrorPage', 0)
    }
    return skip()
  }
  if (!store.getters['Components/errorPage']) {
    store.dispatch('Components/setErrorPage', 403)
  }
  return next()
}
