<template>
  <div>
    <header-bar
      v-model="drawer"
      :window-width="windowWidth"
      :breadcrumbs="breadcrumbs"
      :mini.sync="mini"
      :dark-mode="darkMode"
      @darkMode="setDarkMode($event)" />
    <sidebar-menu
      v-model="drawer"
      :is-mobile="isMobile"
      :items="menus"
      :mini="mini" />
    <v-main>
      <v-fade-transition mode="out-in">
        <router-view v-if="!errorPageCode" />
        <error-page
          v-else
          :error-code="errorPageCode" />
      </v-fade-transition>
    </v-main>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import sidebarMenu from '@/components/SidebarMenu'
import headerBar from '@/components/HeaderBar'
import errorPage from '@/components/layout/Error'
import sidebarMenus from '@/assets/js/menu/SidebarMenus'

export default {
  components: {
    sidebarMenu,
    headerBar,
    errorPage
  },
  props: {
    windowWidth: {
      type: Number,
      default: 0
    },
    windowHeight: {
      type: Number,
      default: 0
    },
    isMobile: {
      type: Boolean
    }
  },
  data () {
    return {
      menus: sidebarMenus,
      brandName: '',
      drawer: false,
      isUpToDate: true,
      mini: false
    }
  },
  computed: {
    ...mapGetters({
      darkMode: 'Style/darkMode',
      errorPageCode: 'Components/errorPage'
    }),
    breadcrumbs () {
      const breadcrumbs = [
        {
          disabled: false,
          link: true,
          text: 'Dashboard',
          href: this.$router.resolve({ name: 'Dashboard' }).href
        }
      ]
      if (this.$route.name !== 'Dashboard') {
        const newMenus = this.getBreadcrumbs(this.menus, this.$route.name)
        const mapMenus = newMenus.map((r) => ({
          disabled: false,
          link: true,
          text: r.title,
          href: this.$router.resolve({ name: r.to }).href
        }))
        breadcrumbs.push(...mapMenus)
      }
      breadcrumbs[breadcrumbs.length - 1].disabled = true
      return breadcrumbs
    }
  },
  watch: {
    isMobile (val) {
      if (val) {
        this.drawer = false
      }
    }
  },
  methods: {
    ...mapActions({
      setDarkMode: 'Style/setDarkMode'
    }),
    getBreadcrumbs (data, value) {
      let newData = []
      for (let i = 0; i < data.length; i++) {
        if (data[i].to === value) {
          newData = [{ ...data[i] }]
          break
        } else if (data[i].subMenus) {
          const sub = this.getBreadcrumbs(data[i].subMenus, value)
          if (sub[0] && sub[0].to) {
            newData.push({ ...data[i] })
            newData.push({ ...sub[0] })
            break
          }
        } else if (data[i].breadcrumbs) {
          const sub = this.getBreadcrumbs(data[i].breadcrumbs, value)
          if (sub[0] && sub[0].to) {
            newData.push({ ...data[i] })
            newData.push({ ...sub[0] })
            break
          }
        }
      }
      return newData
    }
  }
}
</script>
