import Vue from 'vue'
import Vuetify from 'vuetify/lib'

import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'mdi'
  },
  theme: {
    themes: {
      light: {
        primary: '#155e74',
        secondary: '#333333',
        accent: '#8c9eff',
        error: '#EF5350',
        info: '#26C6DA',
        success: '#2E7D32',
        warning: '#fcba03',
        orange: '#FF9800'
      }
    }
  }
})
