import Auth from './Auth'
import Accounting from './roles/Accounting'
import ActingAssistStoreManager from './roles/ActingAssistStoreManager'
import AreaManager from './roles/AreaManager'
import AssistStoreManager from './roles/AssistStoreManager'
import Creative from './roles/Creative'
import Designer from './roles/Designer'
import Developer from './roles/Developer'
import Graphic from './roles/Graphic'
import Management from './roles/Management'
import Marketing from './roles/Marketing'
import Merchandiser from './roles/Merchandiser'
import MerchandisingPlanner from './roles/MerchandisingPlanner'
import OnlineAdmin from './roles/OnlineAdmin'
import SalesStaff from './roles/SalesStaff'
import StoreManager from './roles/StoreManager'
import Support from './roles/Support'
import ToBeAssigned from './roles/ToBeAssigned'
import Warehouse from './roles/Warehouse'
import WarehouseManager from './roles/WarehouseManager'
import Vm from './roles/Vm'
import AccountingManager from './roles/AccountingManager'
import OfficeAdmin from './roles/OfficeAdmin'
import InventoryAndCosting from './roles/InventoryAndCosting'

export default {
  auth: Auth,
  management: Management,
  area_manager: AreaManager,
  marketing: Marketing,
  developer: Developer,
  designer: Designer,
  merchandising_planner: MerchandisingPlanner,
  merchandiser: Merchandiser,
  creative: Creative,
  graphic: Graphic,
  online_admin: OnlineAdmin,
  office_admin: OfficeAdmin,
  accounting: Accounting,
  accounting_manager: AccountingManager,
  vm: Vm,
  support: Support,
  warehouse_manager: WarehouseManager,
  warehouse: Warehouse,
  store_manager: StoreManager,
  inventory_and_costing: InventoryAndCosting,
  acting_assist_store_manager: ActingAssistStoreManager,
  assist_store_manager: AssistStoreManager,
  sales_staff: SalesStaff,
  to_be_assigned: ToBeAssigned
}
