import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import StyleModule from './modules/StyleModule'
import StoreModule from './modules/StoreModule'
import RouteModule from './modules/RouteModule'
import ComponentsModule from './modules/ComponentsModule'
import PrototypesModule from './modules/PrototypesModule'
import MemberModule from './modules/MemberModule'
import OrderModule from './modules/OrderModule'

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  restoreState: (key, storage) => {
    const data = storage[key] ? JSON.parse(storage[key]) : {}
    if (data?.Order?.orderIdSelected) {
      delete data.Order.orderIdSelected
      delete data.Order.statusList
    }
    return data
  },
  modules: ['Store', 'Style', 'Prototypes', 'Member', 'Order']
})

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    Components: ComponentsModule,
    Route: RouteModule,
    Store: StoreModule,
    Style: StyleModule,
    Prototypes: PrototypesModule,
    Member: MemberModule,
    Order: OrderModule
  },
  plugins: [
    vuexLocal.plugin
  ]
})
