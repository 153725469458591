import Vue from 'vue'
import dayjs from 'dayjs'

Vue.filter('showFullPriceFormat', (number) => {
  if (!number && number !== 0) return '-'
  const num = parseFloat(number)
  if (num === 0 || num) {
    return parseFloat(num).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }
  return '-'
})
const isInt = (n) => n % 1 === 0
Vue.filter('showNumberFormat', (number) => {
  if (!number && number !== 0) return '-'
  const num = parseFloat(number)
  if (num === 0 || num) {
    const toFixed = isInt(+num) ? 0 : 2
    return parseFloat(number).toFixed(toFixed).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }
  return '-'
})
Vue.filter('paddingNumber', (number = 0) => {
  const strNumber = number.toString()
  return strNumber.length <= 5 ? strNumber.padStart(5, '0') : strNumber.padStart(strNumber.length, '0')
})

Vue.filter('toCapitalize', (text) => {
  if (!text) {
    return '-'
  }

  return text.replace(/[a-z]/, (w) => w.toUpperCase())
})

Vue.filter('dateFormat', (date) => (date ? dayjs(date).format('YYYY-MM-DD') : '-'))

Vue.filter('dateTimeFormat', (date) => (date ? dayjs(date).format('YYYY-MM-DD HH:mm') : '-'))

Vue.filter('dateUserFormat', (date) => (date ? dayjs(date).format('DD/MM/YYYY') : '-'))

Vue.filter('dateTimeUserFormat', (date) => (date ? dayjs(date).format('DD/MM/YYYY HH:mm') : '-'))
