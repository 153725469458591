import { getAuthToken } from '@/assets/js/Authentication'
import HttpRequest from './HttpRequest'

class UserProvider extends HttpRequest {
  getUsers (query) {
    this.setHeader(getAuthToken())
    return this.getByPagination('/users/store/list-user', query)
  }

  getUserBranch (userId) {
    this.setHeader(getAuthToken())
    return this.get(`user/branchs/${userId}/list`)
  }

  getUsersByBranch (branchId) {
    this.setHeader(getAuthToken())
    return this.get(`user/branchs/${branchId}/users`)
  }

  createUserBranch (payload) {
    this.setHeader(getAuthToken())
    return this.post('user/branchs', payload)
  }

  updateUser (id, data) {
    this.setHeader(getAuthToken())
    return this.put(`/users/store/${id}`, data)
  }

  deleteUser (id) {
    this.setHeader(getAuthToken())
    return this.remove(`/users/store?id=${id}`)
  }

  deleteUserBranch (id) {
    this.setHeader(getAuthToken())
    return this.remove(`/user/branchs?id=${id}`)
  }
}

export default UserProvider
