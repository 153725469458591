const state = {
  darkMode: false,
  isWebpSupported: false
}

const actions = {
  setIsWebpSupported ({ commit }) {
    let isWebpSupported = false
    const elem = document.createElement('canvas')
    if (elem.getContext && elem.getContext('2d')) {
      isWebpSupported = elem.toDataURL('image/webp').indexOf('data:image/webp') === 0
    }
    commit('SET_IS_WEBP_SUPPORTED', isWebpSupported)
  },
  setDarkMode ({ commit }, payload) {
    commit('SET_DARKMODE', payload)
  }
}

const mutations = {
  SET_DARKMODE (state, payload) {
    state.darkMode = payload
  },
  SET_IS_WEBP_SUPPORTED (state, payload) {
    state.isWebpSupported = payload
  }
}

const getters = {
  darkMode: (state) => state.darkMode,
  isWebpSupported: (state) => state.isWebpSupported
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
