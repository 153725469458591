import S3 from 'aws-sdk/clients/s3'
import ImageCompression from 'browser-image-compression'
import heic2any from 'heic2any'

const uploadImage = (file, path) => {
  const fileType = file.name.split('.')
  const fileName = `${new Date().getTime()}.${fileType[fileType.length - 1]}`
  const params = {
    Key: fileName,
    ContentType: file.type,
    Body: file,
    ACL: 'public-read'
  }

  const bucket = new S3({
    region: process.env.VUE_APP_S3_REGION,
    accessKeyId: process.env.VUE_APP_S3_ACCESSKEYID,
    secretAccessKey: process.env.VUE_APP_S3_SECRETACCESSKEY,
    params: {
      Bucket: `${process.env.VUE_APP_S3_BUCKET}${path}`
    }
  })

  return new Promise((resolve, reject) => {
    bucket.putObject(params, function a (err) {
      if (err) {
        reject(err)
      } else {
        resolve(this.request.params)
      }
    })
  })
}

const determineMIMEType = async (blob) => new Promise((resolve) => {
  const reader = new FileReader()
  reader.onload = (event) => {
    const header = event.target.result.slice(0, 4)
    const headerView = new Uint8Array(header)
    const signature = [headerView[0], headerView[1], headerView[2], headerView[3]]
    const types = [
      { type: 'image/png', signature: [137, 80, 78, 71] },
      { type: 'image/jpeg', signature: [255, 216, 255, 224] },
      { type: 'image/jpg', signature: [255, 216, 255, 225] },
      { type: 'image/jpg', signature: [255, 216, 255, 237] },
      { type: 'image/webp', signature: [82, 73, 70, 70] },
      { type: 'image/gif', signature: [71, 73, 70, 56] },
      { type: 'image/heic', signature: [0, 0, 0, 40] }
    ]

    for (const type of types) {
      if (signature.every((value, index) => value === type.signature[index])) {
        const fileType = type.type
        const extension = fileType.split('/')[1].toLowerCase() || 'jpeg'
        const fileName = blob.name
          .toLowerCase()
          .includes(`.${extension}`) ? blob.name : `${blob.name}.${extension}`
        const newFile = new Blob([blob], { type: fileType })
        newFile.name = fileName

        resolve(newFile)
        return
      }
    }

    resolve(null)
  }
  reader.readAsArrayBuffer(blob.slice(0, 4))
})

const compressFileSize = async (file) => {
  const options = {
    maxSizeMB: 2,
    maxWidthOrHeight: 1920,
    useWebWorker: true
  }

  if (!file?.type || !file?.name?.includes('.')) {
    const newFile = await determineMIMEType(file)

    return ImageCompression(newFile, options)
  }

  return ImageCompression(file, options)
}

const uploadToBucket = async (_file, path, needCompress = false) => {
  let file = _file

  if (_file.type === 'image/heic') {
    file = await heic2any({ blob: _file, toType: 'image/png' })
    file.name = `${+(new Date())}.png`
  }

  file = needCompress ? await compressFileSize(file) : file

  let image = null
  try {
    const res = await uploadImage(file, path)
    image = {
      mediaName: res.Body.name,
      mediaUrl: process.env.VUE_APP_S3_URL + res.Key,
      size: res.Body.size,
      mediaType: res.Body.type,
      tempId: res.Body.uid
    }
  } catch (e) {
    console.error('uploadToBucket catch', e)
  }
  return image
}

export default uploadToBucket

export { uploadToBucket }
