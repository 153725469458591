<template>
  <div
    ref="product-image"
    class="product-image"
    @click="click()"
    @mouseout="mouseout()"
    @mouseover="mouseover()">
    <div
      v-if="img && isImageUrl"
      ref="img-ratio"
      class="img-ratio ratio-4-3 elevation-1">
      <img
        :src="ratioImageUrl"
        alt="product-image">
    </div>
    <v-img
      v-else-if="isImageUrl"
      :aspect-ratio="0.75"
      :src="imageUrl"
      :lazy-src="lazyImageUrl"
      :width="100"
      class="elevation-1 main-image"
      alt="product-image">
    </v-img>
    <div
      v-else
      ref="mock-image"
      class="mock-image elevation-1">
      <gw-svg-icon
        class="mock-image-svg"
        svg-name="shirt"
        :width="svgWidth()"
        :height="svgWidth()" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      default: ''
    },
    customSvg: {
      type: [String, Number],
      default: null
    },
    lazyWidth: {
      type: Number,
      default: 2
    },
    lazyHeight: {
      type: Number,
      default: 2
    },
    img: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      svgImageWidth: 0,
      ratioImageWidth: 0,
      ratioImageHeight: 0
    }
  },
  computed: {
    imageUrl () {
      return this.src
    },
    lazyImageUrl () {
      return `${this.replaceURL(this.src)}?w=${this.lazyWidth}&h=${this.lazyHeight}&fit=clamp`
    },
    ratioImageUrl () {
      return `${this.replaceURL(this.src)}?w=${this.ratioImageWidth}&h=${this.ratioImageHeight}&fit=clamp`
    },
    isImageUrl () {
      return !!this.imageUrl
    }
  },
  mounted () {
    this.setSvgImageWidth()
    this.setRatioImageSize()
  },
  methods: {
    click () {
      this.$emit('click')
    },
    mouseout () {
      this.$emit('mouseout')
    },
    mouseover () {
      this.$emit('mouseover')
    },
    replaceURL (value) {
      return value.replace(process.env.VUE_APP_S3_URL, process.env.VUE_APP_S3_IMGIX)
    },
    setSvgImageWidth () {
      if (!this.isImageUrl || (!this.img && !this.isImageUrl)) {
        this.svgImageWidth = this.$refs['mock-image'].offsetWidth
      }
    },
    svgWidth () {
      let width = 100
      if (this.customSvg === 'auto') {
        width = this.svgImageWidth * 0.85
      } else if (this.customSvg) {
        width = this.customSvg
      }
      return width.toString()
    },
    setRatioImageSize () {
      if (this.img && this.isImageUrl) {
        this.ratioImageWidth = this.$refs['img-ratio'].offsetWidth
        this.ratioImageHeight = this.$refs['img-ratio'].offsetHeight
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.product-image {
  width: 100%;
  .main-image {
    display: block;
    margin: auto;
  }
  .mock-image {
    position: relative;
    padding-bottom: 133.333%;
  }
  .mock-image-svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.img-ratio {
  position: relative;
  img {
    position: absolute;
    display: block;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
.img-ratio.ratio-4-3 {
  padding-bottom: 133.333%;
}
</style>
