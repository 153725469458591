export default {
  path: '/cash-receipt',
  component: () => import('@/views/cashReceipt/index.vue'),
  children: [
    {
      path: '',
      name: 'CashReceipt',
      component: () => import('@/views/cashReceipt/pages/CashReceipt.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'developer',
          'accounting_manager',
          'support'
        ]
      }
    },
    {
      path: 'create',
      name: 'CreateCashReceipt',
      component: () => import('@/views/cashReceipt/pages/CreateCashReceipt.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'developer',
          'accounting_manager',
          'support'
        ]
      }
    }
  ]
}
