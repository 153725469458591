export default {
  path: '/pos-management',
  component: () => import('@/views/posManagement/index.vue'),
  children: [
    {
      path: '',
      name: 'PosManagement',
      component: () => import('@/views/posManagement/pages/list.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'accounting_manager',
          'marketing',
          'developer',
          'support'
        ]
      }
    },
    {
      path: 'create',
      name: 'CreatePos',
      component: () => import('@/views/posManagement/pages/create.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'accounting_manager',
          'marketing',
          'developer',
          'support'
        ]
      }
    },
    {
      path: 'edit/:id',
      name: 'EditPos',
      component: () => import('@/views/posManagement/pages/edit.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'accounting_manager',
          'marketing',
          'developer',
          'support'
        ]
      }
    },
    {
      path: 'preview/:id',
      name: 'PreviewPosData',
      component: () => import('@/views/posManagement/pages/preview.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'accounting_manager',
          'marketing',
          'developer',
          'support'
        ]
      }
    }
  ]
}
