export default {
  path: '/line-myshop',
  component: () => import('@/views/lineMyShop/index.vue'),
  children: [
    {
      path: '',
      name: 'LineMyShop',
      component: () => import('@/views/lineMyShop/pages/LineMyShop.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'accounting_manager',
          'marketing',
          'developer',
          'online_admin',
          'support'
        ]
      }
    },
    {
      path: 'create',
      name: 'LineMyShopCreateForm',
      component: () => import('@/views/lineMyShop/pages/LineMyShopForm.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'accounting_manager',
          'marketing',
          'developer',
          'online_admin',
          'support'
        ]
      }
    },
    {
      path: 'edit/:id',
      name: 'LineMyShopEditForm',
      component: () => import('@/views/lineMyShop/pages/LineMyShopForm.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'accounting_manager',
          'marketing',
          'developer',
          'online_admin',
          'support'
        ]
      }
    }
  ]
}
