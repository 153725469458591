import Vue from 'vue'
import { ValidationObserver, ValidationProvider, extend, configure } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import i18n from './VueI18n'

configure({
  defaultMessage: (field, values) => {
    const newValues = values
    newValues._field_ = i18n.t(`fields.${field}`)
    return i18n.t(`validation.${newValues._rule_}`, newValues)
  }
})

Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule])
})

extend('eng-thValidator', {
  validate (value) {
    const REGEX_ENGTH = /^[A-Za-zก-๏]*$/
    return REGEX_ENGTH.test(value)
  }
})

Vue.component('validation-provider', ValidationProvider)
Vue.component('validation-observer', ValidationObserver)
