const state = {
  prototypes: [],
  orders: [],
  fabricOrder: null
}

const actions = {
  setPrototypes ({ commit }, payload) {
    commit('SET_PROTOTYPES', payload)
  },
  resetPrototypes ({ commit }) {
    commit('RESET_PROTOTYPES')
  },
  setOrders ({ commit }, payload) {
    commit('SET_ORDERS', payload)
  },
  resetOrders ({ commit }) {
    commit('RESET_ORDERS')
  },
  setFabricOrder ({ commit }, payload) {
    commit('SET_FABRIC_ORDER', payload)
  }
}

const mutations = {
  SET_PROTOTYPES (state, payload) {
    state.prototypes = payload
  },
  RESET_PROTOTYPES (state) {
    state.prototypes = []
  },
  SET_ORDERS (state, payload) {
    state.orders = payload
  },
  RESET_ORDERS (state) {
    state.orders = []
  },
  SET_FABRIC_ORDER (state, payload) {
    state.fabricOrder = payload
  }
}

const getters = {
  prototypes: (state) => state.prototypes,
  orders: (state) => state.orders,
  fabricOrder: (state) => state.fabricOrder
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
