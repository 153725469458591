import jwtDecode from 'jwt-decode'
import Cookies from 'js-cookie'

const storageKey = 'CampAccessToken'

export const getAuth = () => {
  const auth = Cookies.getJSON(storageKey)
  return auth
}

export const getAuthDecode = () => {
  const auth = Cookies.getJSON(storageKey)
  return auth ? jwtDecode(auth) : null
}

export const getRole = () => getAuthDecode()?.role || null

export const setAuthUser = (token) => {
  const { exp } = jwtDecode(token)
  const date = exp * 1000
  Cookies.set(storageKey, token, {
    expires: new Date(date)
  })
  return getAuth()
}

export const clearAuth = () => {
  Cookies.remove(storageKey)
}

export const getAuthToken = () => {
  const token = {}
  if (Cookies.getJSON(storageKey)) {
    token.key = 'Authorization'
    token.value = `Bearer ${Cookies.getJSON(storageKey)}`
  }
  return token
}

export const checkRole = (item, role, router) => {
  const menu = item
  const { route } = router.resolve({ name: menu.to })
  if (route?.meta?.middleware) {
    menu.permission = new Array(...route.meta.middleware)
    menu.permission.splice(menu.permission.indexOf('auth'), 1)
  }
  return (!!menu.permission && menu.permission.some((p) => p === role)) || !menu.permission || menu.permission.length === 0
}

export const getMenuByRole = (items, router) => {
  const newItems = []
  const role = getRole()
  items.forEach((item) => {
    if (item.subMenus) {
      const subMenus = item.subMenus.filter((r) => checkRole(r, role, router))
      if (subMenus.length > 0) {
        newItems.push({
          title: item.title,
          icon: item.icon,
          subMenus
        })
      }
    } else if (checkRole(item, role, router)) {
      newItems.push(item)
    }
  })
  return newItems
}

export default {
  setAuthUser,
  getAuth,
  getAuthToken,
  clearAuth,
  getAuthDecode,
  getMenuByRole
}
