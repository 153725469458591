export default {
  path: '/clickcollect',
  component: () => import('@/views/clickcollect/index.vue'),
  children: [
    {
      path: 'stock-picking',
      name: 'StockPickingList',
      component: () => import('@/views/clickcollect/pages/StockPickingList.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'area_manager',
          'developer',
          'accounting_manager',
          'support',
          'warehouse_manager',
          'warehouse',
          'store_manager',
          'acting_assist_store_manager',
          'assist_store_manager',
          'sales_staff'
        ]
      }
    }
  ]
}
