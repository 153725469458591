import { getAuthToken } from '@/assets/js/Authentication'
import HttpRequest from './HttpRequest'

class ShippingProvider extends HttpRequest {
  getShipping () {
    this.setHeader(getAuthToken())
    return this.get('/shipping')
  }

  deleteShipping (id) {
    this.setHeader(getAuthToken())
    return this.delete(`/shipping?id=${id}`)
  }

  createShipping (payload) {
    this.setHeader(getAuthToken())
    return this.post('/shipping', payload)
  }

  updateShipping (id, payload) {
    this.setHeader(getAuthToken())
    return this.put(`/shipping/${id}`, payload)
  }
}

export default ShippingProvider
